import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const bookingsAdapter = createEntityAdapter({});

const initialState = bookingsAdapter.getInitialState();

export const bookingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBookings: builder.query({
      query: () => ({
        url: "/bookings",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedBookings = responseData.map((booking) => {
          booking.id = booking._id;
          return booking;
        });
        return bookingsAdapter.setAll(initialState, loadedBookings);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Booking", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Booking", id })),
          ];
        } else return [{ type: "Booking", id: "LIST" }];
      },
    }),
    getAllBookingsWithRejected: builder.query({
      query: () => ({
        url: "/bookings/getAllBookingsWithRejected",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedBookings = responseData.map((booking) => {
          booking.id = booking._id;
          return booking;
        });
        return bookingsAdapter.setAll(initialState, loadedBookings);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Booking", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Booking", id })),
          ];
        } else return [{ type: "Booking", id: "LIST" }];
      },
    }),
    // addNewBooking: builder.mutation({
    //   query: (initialBooking) => ({
    //     url: "/bookings",
    //     method: "POST",
    //     body: {
    //       ...initialBooking,
    //     },
    //   }),
    //   invalidatesTags: [{ type: "Booking", id: "LIST" }],
    // }),
    // updateBooking: builder.mutation({
    //   query: (initialBooking) => ({
    //     url: "/bookings",
    //     method: "PATCH",
    //     body: {
    //       ...initialBooking,
    //     },
    //   }),
    //   invalidatesTags: (result, error, arg) => [
    //     { type: "Booking", id: arg.id },
    //   ],
    // }),
    addNewBooking: builder.mutation({
      query: (initialBookingData) => {
        const formData = new FormData();

        // Basic booking details
        formData.append("vehicle", initialBookingData.vehicle);
        formData.append("dealership", initialBookingData.dealership);
        formData.append("pickUpLocation", initialBookingData.pickUpLocation);
        formData.append("dropOffLocation", initialBookingData.dropOffLocation);

        // Pick up location in depth
        formData.append(
          "pickUpLocationInDepth[type]",
          initialBookingData.pickUpLocationInDepth.type
        );
        if (initialBookingData.pickUpLocationInDepth.address) {
          formData.append(
            "pickUpLocationInDepth[address][line1]",
            initialBookingData.pickUpLocationInDepth.address.line1 || ""
          );
          formData.append(
            "pickUpLocationInDepth[address][line2]",
            initialBookingData.pickUpLocationInDepth.address.line2 || ""
          );
          formData.append(
            "pickUpLocationInDepth[address][city]",
            initialBookingData.pickUpLocationInDepth.address.city || ""
          );
          formData.append(
            "pickUpLocationInDepth[address][county]",
            initialBookingData.pickUpLocationInDepth.address.county || ""
          );
          formData.append(
            "pickUpLocationInDepth[address][postCode]",
            initialBookingData.pickUpLocationInDepth.address.postCode || ""
          );
        }

        // Drop off location in depth
        formData.append(
          "dropOffLocationInDepth[type]",
          initialBookingData.dropOffLocationInDepth.type
        );
        if (initialBookingData.dropOffLocationInDepth.address) {
          formData.append(
            "dropOffLocationInDepth[address][line1]",
            initialBookingData.dropOffLocationInDepth.address.line1 || ""
          );
          formData.append(
            "dropOffLocationInDepth[address][line2]",
            initialBookingData.dropOffLocationInDepth.address.line2 || ""
          );
          formData.append(
            "dropOffLocationInDepth[address][city]",
            initialBookingData.dropOffLocationInDepth.address.city || ""
          );
          formData.append(
            "dropOffLocationInDepth[address][county]",
            initialBookingData.dropOffLocationInDepth.address.county || ""
          );
          formData.append(
            "dropOffLocationInDepth[address][postCode]",
            initialBookingData.dropOffLocationInDepth.address.postCode || ""
          );
        }

        // Dates and times
        formData.append("startDate", initialBookingData.startDate);
        formData.append("endDate", initialBookingData.endDate);
        formData.append("startTime", initialBookingData.startTime);
        formData.append("endTime", initialBookingData.endTime);
        formData.append("dateOfBooking", initialBookingData.dateOfBooking);

        // Order and payment details
        formData.append("orderNumber", initialBookingData.orderNumber);
        formData.append("totalPrice", initialBookingData.totalPrice);
        formData.append("deliveryFee", initialBookingData.deliveryFee || 0);
        formData.append("chauffeur", initialBookingData.chauffeur);
        formData.append("chauffeurFee", initialBookingData.chauffeurFee || 0);
        formData.append(
          "chauffeurDetails",
          initialBookingData.chauffeurDetails || ""
        );
        formData.append("description", initialBookingData.description || "");
        formData.append(
          "isManualBooking",
          initialBookingData.isManualBooking || false
        );

        // Main customer information
        formData.append(
          "mainCustomer[customer]",
          initialBookingData.mainCustomer.customer || ""
        );
        formData.append(
          "mainCustomer[fullname]",
          initialBookingData.mainCustomer.fullname
        );

        // Main customer driving license
        formData.append(
          "mainCustomer[drivingLicense][number]",
          initialBookingData.mainCustomer.drivingLicense.number
        );
        formData.append(
          "mainCustomer[drivingLicense][dateOfIssue]",
          initialBookingData.mainCustomer.drivingLicense.dateOfIssue
        );
        formData.append(
          "mainCustomer[drivingLicense][dateOfExpiry]",
          initialBookingData.mainCustomer.drivingLicense.dateOfExpiry
        );
        formData.append(
          "mainCustomer[drivingLicense][placeOfIssue]",
          initialBookingData.mainCustomer.drivingLicense.placeOfIssue
        );

        // Main customer passport
        formData.append(
          "mainCustomer[passport][number]",
          initialBookingData.mainCustomer.passport.number
        );
        formData.append(
          "mainCustomer[passport][dateOfIssue]",
          initialBookingData.mainCustomer.passport.dateOfIssue
        );
        formData.append(
          "mainCustomer[passport][dateOfExpiry]",
          initialBookingData.mainCustomer.passport.dateOfExpiry
        );

        // Main customer other details
        formData.append(
          "mainCustomer[dateOfBirth]",
          initialBookingData.mainCustomer.dateOfBirth
        );

        // Main customer address
        formData.append(
          "mainCustomer[address][line1]",
          initialBookingData.mainCustomer.address.line1
        );
        formData.append(
          "mainCustomer[address][line2]",
          initialBookingData.mainCustomer.address.line2 || ""
        );
        formData.append(
          "mainCustomer[address][city]",
          initialBookingData.mainCustomer.address.city
        );
        formData.append(
          "mainCustomer[address][county]",
          initialBookingData.mainCustomer.address.county || ""
        );
        formData.append(
          "mainCustomer[address][postCode]",
          initialBookingData.mainCustomer.address.postCode
        );

        // Main customer connections
        formData.append(
          "mainCustomer[connections][email]",
          initialBookingData.mainCustomer.connections.email
        );
        formData.append(
          "mainCustomer[connections][number]",
          initialBookingData.mainCustomer.connections.number
        );

        // Main customer license images
        if (initialBookingData.mainCustomer.frontDrivingLicenseImg) {
          formData.append(
            "mainCustomer[frontDrivingLicenseImg]",
            initialBookingData.mainCustomer.frontDrivingLicenseImg
          );
        }
        if (initialBookingData.mainCustomer.backDrivingLicenseImg) {
          formData.append(
            "mainCustomer[backDrivingLicenseImg]",
            initialBookingData.mainCustomer.backDrivingLicenseImg
          );
        }

        // Additional driver information (if provided)
        if (initialBookingData.additionalDriver) {
          formData.append(
            "additionalDriver[customer]",
            initialBookingData.additionalDriver.customer || ""
          );

          // Only append additional driver details if they exist
          if (initialBookingData.additionalDriver.drivingLicense) {
            formData.append(
              "additionalDriver[drivingLicense][number]",
              initialBookingData.additionalDriver.drivingLicense.number || ""
            );
            formData.append(
              "additionalDriver[drivingLicense][dateOfIssue]",
              initialBookingData.additionalDriver.drivingLicense.dateOfIssue ||
                ""
            );
            formData.append(
              "additionalDriver[drivingLicense][dateOfExpiry]",
              initialBookingData.additionalDriver.drivingLicense.dateOfExpiry ||
                ""
            );
            formData.append(
              "additionalDriver[drivingLicense][placeOfIssue]",
              initialBookingData.additionalDriver.drivingLicense.placeOfIssue ||
                ""
            );
          }

          if (initialBookingData.additionalDriver.passport) {
            formData.append(
              "additionalDriver[passport][number]",
              initialBookingData.additionalDriver.passport.number || ""
            );
            formData.append(
              "additionalDriver[passport][dateOfIssue]",
              initialBookingData.additionalDriver.passport.dateOfIssue || ""
            );
            formData.append(
              "additionalDriver[passport][dateOfExpiry]",
              initialBookingData.additionalDriver.passport.dateOfExpiry || ""
            );
          }

          if (initialBookingData.additionalDriver.dateOfBirth) {
            formData.append(
              "additionalDriver[dateOfBirth]",
              initialBookingData.additionalDriver.dateOfBirth
            );
          }

          if (initialBookingData.additionalDriver.address) {
            formData.append(
              "additionalDriver[address][line1]",
              initialBookingData.additionalDriver.address.line1 || ""
            );
            formData.append(
              "additionalDriver[address][line2]",
              initialBookingData.additionalDriver.address.line2 || ""
            );
            formData.append(
              "additionalDriver[address][city]",
              initialBookingData.additionalDriver.address.city || ""
            );
            formData.append(
              "additionalDriver[address][county]",
              initialBookingData.additionalDriver.address.county || ""
            );
            formData.append(
              "additionalDriver[address][postCode]",
              initialBookingData.additionalDriver.address.postCode || ""
            );
          }

          if (initialBookingData.additionalDriver.connections) {
            formData.append(
              "additionalDriver[connections][email]",
              initialBookingData.additionalDriver.connections.email || ""
            );
            formData.append(
              "additionalDriver[connections][number]",
              initialBookingData.additionalDriver.connections.number || ""
            );
          }

          // Additional driver license images
          if (
            initialBookingData.additionalDriver
              .additionalDriverFrontDrivingLicenseImg
          ) {
            formData.append(
              "additionalDriver[additionalDriverFrontDrivingLicenseImg]",
              initialBookingData.additionalDriver
                .additionalDriverFrontDrivingLicenseImg
            );
          }
          if (
            initialBookingData.additionalDriver
              .additionalDriverBackDrivingLicenseImg
          ) {
            formData.append(
              "additionalDriver[additionalDriverBackDrivingLicenseImg]",
              initialBookingData.additionalDriver
                .additionalDriverBackDrivingLicenseImg
            );
          }

          // Additional driver fee
          if (initialBookingData.additionalDriverFee) {
            formData.append(
              "additionalDriverFee",
              initialBookingData.additionalDriverFee
            );
          }
        }

        // Booking status and progress
        if (initialBookingData.bookingStatus) {
          formData.append("bookingStatus", initialBookingData.bookingStatus);
        }
        formData.append(
          "bookingProgress",
          initialBookingData.bookingProgress || "To Be Confirmed"
        );
        formData.append("complete", initialBookingData.complete || false);

        // Insurance details
        if (initialBookingData.insuranceDetails) {
          formData.append(
            "insuranceDetails[insuranceCompany]",
            initialBookingData.insuranceDetails.insuranceCompany || ""
          );
          formData.append(
            "insuranceDetails[policyNumber]",
            initialBookingData.insuranceDetails.policyNumber || ""
          );
          formData.append(
            "insuranceDetails[validFrom]",
            initialBookingData.insuranceDetails.validFrom || ""
          );
          formData.append(
            "insuranceDetails[validUntil]",
            initialBookingData.insuranceDetails.validUntil || ""
          );
        }

        // Before and after images
        if (
          initialBookingData.beforeImages &&
          initialBookingData.beforeImages.length > 0
        ) {
          initialBookingData.beforeImages.forEach((image) => {
            formData.append("beforeImages", image);
          });
        }

        if (
          initialBookingData.afterImages &&
          initialBookingData.afterImages.length > 0
        ) {
          initialBookingData.afterImages.forEach((image) => {
            formData.append("afterImages", image);
          });
        }

        // // Payment information
        // if (initialBookingData.stripeSessionId) {
        //   formData.append(
        //     "stripeSessionId",
        //     initialBookingData.stripeSessionId
        //   );
        // }
        // if (initialBookingData.stripePaymentIntentId) {
        //   formData.append(
        //     "stripePaymentIntentId",
        //     initialBookingData.stripePaymentIntentId
        //   );
        // }
        // formData.append(
        //   "paymentStatus",
        //   initialBookingData.paymentStatus || "Pending"
        // );
        // formData.append("depositPaid", initialBookingData.depositPaid || false);
        // formData.append(
        //   "fullPaymentPaid",
        //   initialBookingData.fullPaymentPaid || false
        // );

        return {
          url: "/bookings",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "Booking", id: "LIST" }],
    }),

    updateBooking: builder.mutation({
      query: (initialBookingData) => {
        const formData = new FormData();

        // Include the ID for the update operation
        formData.append("id", initialBookingData.id);

        // Basic booking details
        formData.append("vehicle", initialBookingData.vehicle);
        formData.append("dealership", initialBookingData.dealership);
        formData.append("pickUpLocation", initialBookingData.pickUpLocation);
        formData.append("dropOffLocation", initialBookingData.dropOffLocation);

        // Pick up location in depth
        formData.append(
          "pickUpLocationInDepth[type]",
          initialBookingData.pickUpLocationInDepth.type
        );
        if (initialBookingData.pickUpLocationInDepth.address) {
          formData.append(
            "pickUpLocationInDepth[address][line1]",
            initialBookingData.pickUpLocationInDepth.address.line1 || ""
          );
          formData.append(
            "pickUpLocationInDepth[address][line2]",
            initialBookingData.pickUpLocationInDepth.address.line2 || ""
          );
          formData.append(
            "pickUpLocationInDepth[address][city]",
            initialBookingData.pickUpLocationInDepth.address.city || ""
          );
          formData.append(
            "pickUpLocationInDepth[address][county]",
            initialBookingData.pickUpLocationInDepth.address.county || ""
          );
          formData.append(
            "pickUpLocationInDepth[address][postCode]",
            initialBookingData.pickUpLocationInDepth.address.postCode || ""
          );
        }

        // Drop off location in depth
        formData.append(
          "dropOffLocationInDepth[type]",
          initialBookingData.dropOffLocationInDepth.type
        );
        if (initialBookingData.dropOffLocationInDepth.address) {
          formData.append(
            "dropOffLocationInDepth[address][line1]",
            initialBookingData.dropOffLocationInDepth.address.line1 || ""
          );
          formData.append(
            "dropOffLocationInDepth[address][line2]",
            initialBookingData.dropOffLocationInDepth.address.line2 || ""
          );
          formData.append(
            "dropOffLocationInDepth[address][city]",
            initialBookingData.dropOffLocationInDepth.address.city || ""
          );
          formData.append(
            "dropOffLocationInDepth[address][county]",
            initialBookingData.dropOffLocationInDepth.address.county || ""
          );
          formData.append(
            "dropOffLocationInDepth[address][postCode]",
            initialBookingData.dropOffLocationInDepth.address.postCode || ""
          );
        }

        // Dates and times
        formData.append("startDate", initialBookingData.startDate);
        formData.append("endDate", initialBookingData.endDate);
        formData.append("startTime", initialBookingData.startTime);
        formData.append("endTime", initialBookingData.endTime);
        formData.append("dateOfBooking", initialBookingData.dateOfBooking);

        // Order and payment details
        formData.append("orderNumber", initialBookingData.orderNumber);
        formData.append("totalPrice", initialBookingData.totalPrice);
        formData.append("deliveryFee", initialBookingData.deliveryFee || 0);
        formData.append("chauffeur", initialBookingData.chauffeur);
        formData.append("chauffeurFee", initialBookingData.chauffeurFee || 0);
        formData.append(
          "chauffeurDetails",
          initialBookingData.chauffeurDetails || ""
        );
        formData.append("description", initialBookingData.description || "");
        formData.append(
          "isManualBooking",
          initialBookingData.isManualBooking || false
        );

        // Main customer information
        formData.append(
          "mainCustomer[customer]",
          initialBookingData.mainCustomer.customer || ""
        );
        formData.append(
          "mainCustomer[fullname]",
          initialBookingData.mainCustomer.fullname
        );

        // Main customer driving license
        formData.append(
          "mainCustomer[drivingLicense][number]",
          initialBookingData.mainCustomer.drivingLicense.number
        );
        formData.append(
          "mainCustomer[drivingLicense][dateOfIssue]",
          initialBookingData.mainCustomer.drivingLicense.dateOfIssue
        );
        formData.append(
          "mainCustomer[drivingLicense][dateOfExpiry]",
          initialBookingData.mainCustomer.drivingLicense.dateOfExpiry
        );
        formData.append(
          "mainCustomer[drivingLicense][placeOfIssue]",
          initialBookingData.mainCustomer.drivingLicense.placeOfIssue
        );

        // Main customer passport
        formData.append(
          "mainCustomer[passport][number]",
          initialBookingData.mainCustomer.passport.number
        );
        formData.append(
          "mainCustomer[passport][dateOfIssue]",
          initialBookingData.mainCustomer.passport.dateOfIssue
        );
        formData.append(
          "mainCustomer[passport][dateOfExpiry]",
          initialBookingData.mainCustomer.passport.dateOfExpiry
        );

        // Main customer other details
        formData.append(
          "mainCustomer[dateOfBirth]",
          initialBookingData.mainCustomer.dateOfBirth
        );

        // Main customer address
        formData.append(
          "mainCustomer[address][line1]",
          initialBookingData.mainCustomer.address.line1
        );
        formData.append(
          "mainCustomer[address][line2]",
          initialBookingData.mainCustomer.address.line2 || ""
        );
        formData.append(
          "mainCustomer[address][city]",
          initialBookingData.mainCustomer.address.city
        );
        formData.append(
          "mainCustomer[address][county]",
          initialBookingData.mainCustomer.address.county || ""
        );
        formData.append(
          "mainCustomer[address][postCode]",
          initialBookingData.mainCustomer.address.postCode
        );

        // Main customer connections
        formData.append(
          "mainCustomer[connections][email]",
          initialBookingData.mainCustomer.connections.email
        );
        formData.append(
          "mainCustomer[connections][number]",
          initialBookingData.mainCustomer.connections.number
        );

        // Main customer license images

        formData.append(
          "mainCustomer[frontDrivingLicenseImg]",
          initialBookingData.mainCustomer.frontDrivingLicenseImg || ""
        );

        formData.append(
          "mainCustomer[backDrivingLicenseImg]",
          initialBookingData.mainCustomer.backDrivingLicenseImg || ""
        );

        // Additional driver information (if provided)
        if (initialBookingData.additionalDriver) {
          formData.append(
            "additionalDriver[customer]",
            initialBookingData.additionalDriver.customer || ""
          );

          // Only append additional driver details if they exist
          if (initialBookingData.additionalDriver.drivingLicense) {
            formData.append(
              "additionalDriver[drivingLicense][number]",
              initialBookingData.additionalDriver.drivingLicense.number || ""
            );
            formData.append(
              "additionalDriver[drivingLicense][dateOfIssue]",
              initialBookingData.additionalDriver.drivingLicense.dateOfIssue ||
                ""
            );
            formData.append(
              "additionalDriver[drivingLicense][dateOfExpiry]",
              initialBookingData.additionalDriver.drivingLicense.dateOfExpiry ||
                ""
            );
            formData.append(
              "additionalDriver[drivingLicense][placeOfIssue]",
              initialBookingData.additionalDriver.drivingLicense.placeOfIssue ||
                ""
            );
          }

          if (initialBookingData.additionalDriver.passport) {
            formData.append(
              "additionalDriver[passport][number]",
              initialBookingData.additionalDriver.passport.number || ""
            );
            formData.append(
              "additionalDriver[passport][dateOfIssue]",
              initialBookingData.additionalDriver.passport.dateOfIssue || ""
            );
            formData.append(
              "additionalDriver[passport][dateOfExpiry]",
              initialBookingData.additionalDriver.passport.dateOfExpiry || ""
            );
          }

          if (initialBookingData.additionalDriver.dateOfBirth) {
            formData.append(
              "additionalDriver[dateOfBirth]",
              initialBookingData.additionalDriver.dateOfBirth
            );
          }

          if (initialBookingData.additionalDriver.address) {
            formData.append(
              "additionalDriver[address][line1]",
              initialBookingData.additionalDriver.address.line1 || ""
            );
            formData.append(
              "additionalDriver[address][line2]",
              initialBookingData.additionalDriver.address.line2 || ""
            );
            formData.append(
              "additionalDriver[address][city]",
              initialBookingData.additionalDriver.address.city || ""
            );
            formData.append(
              "additionalDriver[address][county]",
              initialBookingData.additionalDriver.address.county || ""
            );
            formData.append(
              "additionalDriver[address][postCode]",
              initialBookingData.additionalDriver.address.postCode || ""
            );
          }

          if (initialBookingData.additionalDriver.connections) {
            formData.append(
              "additionalDriver[connections][email]",
              initialBookingData.additionalDriver.connections.email || ""
            );
            formData.append(
              "additionalDriver[connections][number]",
              initialBookingData.additionalDriver.connections.number || ""
            );
          }

          // Additional driver license images
          if (
            initialBookingData.additionalDriver
              .additionalDriverFrontDrivingLicenseImg
          ) {
            formData.append(
              "additionalDriver[additionalDriverFrontDrivingLicenseImg]",
              initialBookingData.additionalDriver
                .additionalDriverFrontDrivingLicenseImg
            );
          }
          if (
            initialBookingData.additionalDriver
              .additionalDriverBackDrivingLicenseImg
          ) {
            formData.append(
              "additionalDriver[additionalDriverBackDrivingLicenseImg]",
              initialBookingData.additionalDriver
                .additionalDriverBackDrivingLicenseImg
            );
          }

          // Additional driver fee
          if (initialBookingData.additionalDriverFee) {
            formData.append(
              "additionalDriverFee",
              initialBookingData.additionalDriverFee
            );
          }
        }

        // Booking status and progress
        if (initialBookingData.bookingStatus) {
          formData.append("bookingStatus", initialBookingData.bookingStatus);
        }
        formData.append(
          "bookingProgress",
          initialBookingData.bookingProgress || "To Be Confirmed"
        );
        formData.append("complete", initialBookingData.complete || false);

        // Insurance details
        if (initialBookingData.insuranceDetails) {
          formData.append(
            "insuranceDetails[insuranceCompany]",
            initialBookingData.insuranceDetails.insuranceCompany || ""
          );
          formData.append(
            "insuranceDetails[policyNumber]",
            initialBookingData.insuranceDetails.policyNumber || ""
          );
          formData.append(
            "insuranceDetails[validFrom]",
            initialBookingData.insuranceDetails.validFrom || ""
          );
          formData.append(
            "insuranceDetails[validUntil]",
            initialBookingData.insuranceDetails.validUntil || ""
          );
        }

        // Before and after images
        if (
          initialBookingData.beforeImages &&
          initialBookingData.beforeImages.length > 0
        ) {
          initialBookingData.beforeImages.forEach((image) => {
            formData.append("beforeImages", image);
          });
        }

        if (
          initialBookingData.afterImages &&
          initialBookingData.afterImages.length > 0
        ) {
          initialBookingData.afterImages.forEach((image) => {
            formData.append("afterImages", image);
          });
        }

        // Payment information
        // if (initialBookingData.stripeSessionId) {
        //   formData.append(
        //     "stripeSessionId",
        //     initialBookingData.stripeSessionId
        //   );
        // }
        // if (initialBookingData.stripePaymentIntentId) {
        //   formData.append(
        //     "stripePaymentIntentId",
        //     initialBookingData.stripePaymentIntentId
        //   );
        // }
        // formData.append(
        //   "paymentStatus",
        //   initialBookingData.paymentStatus || "Pending"
        // );
        // formData.append("depositPaid", initialBookingData.depositPaid || false);
        // formData.append(
        //   "fullPaymentPaid",
        //   initialBookingData.fullPaymentPaid || false
        // );

        return {
          url: "/bookings",
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Booking", id: arg.id },
      ],
    }),
    deleteBooking: builder.mutation({
      query: ({ id }) => ({
        url: `/bookings`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Booking", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetBookingsQuery,
  useAddNewBookingMutation,
  useUpdateBookingMutation,
  useDeleteBookingMutation,
  useGetAllBookingsWithRejectedQuery,
} = bookingsApiSlice;

// returns the query result object
export const selectBookingsResult =
  bookingsApiSlice.endpoints.getBookings.select();

// creates memoized selector
const selectBookingsData = createSelector(
  selectBookingsResult,
  (bookingsResult) => bookingsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllBookings,
  selectById: selectBookingById,
  selectIds: selectBookingIds,
  // Pass in a selector that returns the vehicles slice of state
} = bookingsAdapter.getSelectors(
  (state) => selectBookingsData(state) ?? initialState
);
