import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAddNewBookingMutation } from "./bookingsApiSlice";
import { Tooltip } from "react-tooltip";
import useAuth from "../../hooks/useAuth";

function formatDate(dateString) {
  if (!dateString) {
    return ""; // Or another default value, depending on your use case
  }

  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return ""; // Or another default value
  }

  const formattedDate = date.toISOString().split("T")[0];
  return formattedDate;
}

const ManualBookingForm = ({ users, vehicles }) => {
  const [addNewBooking, { isLoading, isSuccess, isError, error }] =
    useAddNewBookingMutation();

  const { isEnterprisePlan } = useAuth();

  //   console.log(vehicles);

  // console.log(users);

  const navigate = useNavigate();

  function getCurrentDateAsString() {
    const currentDate = new Date();

    // Get the year, month, and day components of the current date
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Add 1 to month since it's zero-based
    const day = String(currentDate.getDate()).padStart(2, "0");

    // Create the date string in the format "YYYY-MM-DD"
    const dateString = `${year}-${month}-${day}`;

    return dateString;
  }

  const currentDateAsString = getCurrentDateAsString();

  // for the order number

  const uniqueId = Math.random().toString(36).substring(2, 8);
  const currentDateNow = new Date().getTime();
  const newOrderNumber = `ORD-${currentDateNow}-${uniqueId}`;

  const [vehicle, setVehicle] = useState(""); // Initialized with an empty string
  const [dealership, setDealership] = useState(""); // Initialized with an empty string
  // console.log(dealership);

  //   const [dealershipName, setDealershipName] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  // const [vehicleAvailability, setVehicleAvailability] = useState("");
  const [isManualBooking, setIsManualBooking] = useState(true);

  //   const [vehicleHomeDelivery, setVehicleHomeDelivery] = useState("");

  // Use an effect to update `selectedVehicle` when `vehicle` changes
  useEffect(() => {
    if (vehicle) {
      // Find the vehicle object based on the selected ID
      const matchedVehicle = vehicles.find((v) => v.id === vehicle);
      setSelectedVehicle(matchedVehicle); // Update `selectedVehicle` with the found object
    } else {
      setSelectedVehicle(null); // Reset `selectedVehicle` if no ID is selected
    }
  }, [vehicle, vehicles]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const vehicleId = urlParams.get("vehicleId");

    const selectedVehicle = vehicles.find(
      (vehicle) => vehicle.id === vehicleId
    );

    if (selectedVehicle) {
      setVehicle(selectedVehicle.id);
      setDealership(selectedVehicle.owner); // Assuming 'owner' property exists in the vehicle data
      // Update other state variables as needed
      //   setDealershipName(selectedVehicle.companyName);
      setSelectedVehicle(selectedVehicle);
      // setVehicleAvailability(selectedVehicle.availability);
      //   setVehicleHomeDelivery(selectedVehicle.delivery.acceptHomeDelivery);
    }
  }, [vehicles]);

  //   for the total price

  //   const calculateDuration = () => {
  //     if (startDate && endDate) {
  //       const start = new Date(startDate);
  //       const end = new Date(endDate);
  //       const durationInMs = Math.abs(end - start);
  //       const millisecondsPerDay = 1000 * 60 * 60 * 24; // milliseconds in a day
  //       const durationInDays = Math.ceil(durationInMs / millisecondsPerDay);
  //       return durationInDays;
  //     }
  //     return 0; // If the dates are not both provided
  //   };

  //   const duration = calculateDuration();

  const [totalPrice, setTotalPrice] = useState("");

  const [pickUpLocation, setPickUpLocation] = useState("Dealership");
  const [dropOffLocation, setDropOffLocation] = useState("Dealership");

  const [pickUpLocationInDepth, setPickUpLocationInDepth] = useState({
    type: "Dealership", // Initially empty, will hold either 'Dealership' or 'Custom'
    address: {
      line1: "",
      line2: "",
      city: "",
      county: "",
      postCode: "",
    },
  });

  const [dropOffLocationInDepth, setDropOffLocationInDepth] = useState({
    type: "Dealership", // Initially empty, will hold either 'Dealership' or 'Custom'
    address: {
      line1: "",
      line2: "",
      city: "",
      county: "",
      postCode: "",
    },
  });

  const [dateOfBooking, setDateOfBooking] = useState(currentDateAsString);
  const [orderNumber, setOrderNumber] = useState(newOrderNumber);
  const [chauffeur, setChauffeur] = useState(false);
  const [description, setDescription] = useState("");
  const [mainCustomer, setMainCustomer] = useState({
    customer: "",
    fullname: "",
    drivingLicense: {
      number: "",
      dateOfIssue: "",
      dateOfExpiry: "",
      placeOfIssue: "",
    },
    passport: {
      number: "",
      dateOfIssue: "",
      dateOfExpiry: "",
    },
    dateOfBirth: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      county: "",
      postCode: "",
    },
    connections: {
      email: "",
      number: "",
    },
    frontDrivingLicenseImg: "",
    backDrivingLicenseImg: "",
  });
  const [additionalDriver, setAdditionalDriver] = useState({
    customer: "",
    drivingLicense: {
      number: "",
      dateOfIssue: "",
      dateOfExpiry: "",
      placeOfIssue: "",
    },
    passport: {
      number: "",
      dateOfIssue: "",
      dateOfExpiry: "",
    },
    dateOfBirth: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      county: "",
      postCode: "",
    },
    connections: {
      email: "",
      number: "",
    },
    additionalDriverFrontDrivingLicenseImg: "",
    additionalDriverBackDrivingLicenseImg: "",
  });

  const [bookingStatus, setBookingStatus] = useState("Preparing Vehicle"); //"Preparing Vehicle", "Vehicle Ready", "Vehicle Sent Out", "Awaiting Vehicle Return", "Vehicle Fufilled and Returned",
  const [complete, setComplete] = useState(false);
  const [insuranceDetails, setInsuranceDetails] = useState({
    insuranceCompany: "",
    policyNumber: "",
    validFrom: "",
    validUntil: "",
  });
  const [beforeImages, setBeforeImages] = useState("");
  const [afterImages, setAfterImages] = useState("");

  const [chauffeurFee, setChauffeurFee] = useState(0);
  const [additionalDriverFee, setAdditionalDriverFee] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [chauffeurDetails, setChauffeurDetails] = useState("");
  const [bookingProgress, setBookingProgress] = useState("");

  useEffect(() => {
    if (users.length > 0) {
      // Set dealership ID to the ID of the first user
      setDealership(users[0].id);
    }
  }, [users]);

  useEffect(() => {
    if (isSuccess) {
      setVehicle("");
      setDealership("");
      setPickUpLocation("");
      setDropOffLocation("");
      setChauffeurFee(0);
      setAdditionalDriverFee(0);
      setDeliveryFee(0);
      setBookingProgress("");
      setChauffeurDetails("");
      setPickUpLocationInDepth({
        type: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          county: "",
          postCode: "",
        },
      });
      setDropOffLocationInDepth({
        type: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          county: "",
          postCode: "",
        },
      });
      setIsManualBooking(true);
      setStartDate("");
      setEndDate("");
      setStartTime("");
      setEndTime("");
      setDateOfBooking("");
      setOrderNumber("");
      setTotalPrice("");
      setChauffeur(false);
      setDescription("");
      setMainCustomer({
        customer: "",
        fullname: "",
        drivingLicense: {
          number: "",
          dateOfIssue: "",
          dateOfExpiry: "",
          placeOfIssue: "",
        },
        passport: {
          number: "",
          dateOfIssue: "",
          dateOfExpiry: "",
        },
        dateOfBirth: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          county: "",
          postCode: "",
        },
        connections: {
          email: "",
          number: "",
        },
        frontDrivingLicenseImg: "",
        backDrivingLicenseImg: "",
      });
      setAdditionalDriver({
        customer: "",
        drivingLicense: {
          number: "",
          dateOfIssue: "",
          dateOfExpiry: "",
          placeOfIssue: "",
        },
        passport: {
          number: "",
          dateOfIssue: "",
          dateOfExpiry: "",
        },
        dateOfBirth: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          county: "",
          postCode: "",
        },
        connections: {
          email: "",
          number: "",
        },
        additionalDriverFrontDrivingLicenseImg: "",
        additionalDriverBackDrivingLicenseImg: "",
      });
      setBookingStatus("");
      setComplete(false);
      setInsuranceDetails({
        insuranceCompany: "",
        policyNumber: "",
        validFrom: "",
        validUntil: "",
      });
      setBeforeImages("");
      setAfterImages("");

      navigate("/dashboard/myOrders");
    }
  }, [isSuccess, navigate]);

  const onVehicleChanged = (e) => setVehicle(e.target.value);
  const onDealershipChanged = (e) => setDealership(e.target.value);
  const onChauffeurDetailsChanged = (e) => setChauffeurDetails(e.target.value);
  const onBookingProgressChanged = (e) => setBookingProgress(e.target.value);

  // Handler for changing chauffeurFee
  const onChauffeurFeeChanged = (e) => {
    setChauffeurFee(e.target.value);
  };

  // Handler for changing additionalDriverFee
  const onAdditionalDriverFeeChanged = (e) => {
    setAdditionalDriverFee(e.target.value);
  };

  // Handler for changing deliveryFee
  const onDeliveryFeeChanged = (e) => {
    setDeliveryFee(e.target.value);
  };

  const handlePickUpLocationChange = (e) => {
    const { name, value } = e.target;

    if (name === "type") {
      // Update the type field directly
      setPickUpLocationInDepth((prevState) => ({
        ...prevState,
        type: value,
      }));
    } else {
      // Update the nested address fields
      setPickUpLocationInDepth((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      }));
    }
  };

  const handleDropOffLocationChange = (e) => {
    const { name, value } = e.target;

    if (name === "type") {
      // Update the type field directly
      setDropOffLocationInDepth((prevState) => ({
        ...prevState,
        type: value,
      }));
    } else {
      // Update the nested address fields
      setDropOffLocationInDepth((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      }));
    }
  };

  //   const onStartDateChanged = (e) => {
  //     const selectedDate = e.target.value;
  //     // To prevent selecting a date before the current date
  //     const currentDate = new Date().toISOString().split("T")[0];
  //     if (selectedDate < currentDate) {
  //       // Don't update the state if selected date is before the current date
  //       return;
  //     }
  //     setStartDate(selectedDate);
  //   };

  const onStartDateChanged = (e) => {
    const selectedDate = e.target.value;
    // Update the state with the selected date
    setStartDate(selectedDate);
  };

  const onEndDateChanged = (e) => {
    const selectedDate = e.target.value;
    // To prevent selecting a date before the start date
    if (selectedDate < startDate) {
      // Don't update the state if selected date is before the start date
      return;
    }
    setEndDate(selectedDate);
  };

  const onStartTimeChanged = (e) => setStartTime(e.target.value);
  const onEndTimeChanged = (e) => setEndTime(e.target.value);

  const onOrderNumberChanged = (e) => setOrderNumber(e.target.value); // we need to generate this
  const onTotalPriceChanged = (e) => setTotalPrice(e.target.value);
  const onChauffeurChanged = (e) => setChauffeur(e.target.checked);

  const onDescriptionChanged = (e) => setDescription(e.target.value);

  const onBookingStatusChanged = (e) => setBookingStatus(e.target.value);
  const onCompleteChanged = (e) => setComplete(e.target.checked);

  const onMainCustomerChanged = (e) => {
    const { name, value } = e.target;

    // Split the name into parts based on the dot (.) to handle nested fields
    const nameParts = name.split(".");

    // Create a copy of the current mainCustomer object to modify
    let updatedMainCustomer = { ...mainCustomer };

    // Use a loop to navigate through the nested structure
    let currentLevel = updatedMainCustomer;
    for (let i = 0; i < nameParts.length; i++) {
      const part = nameParts[i];
      if (i === nameParts.length - 1) {
        // If it's the last part, update the value
        currentLevel[part] = value;
      } else {
        // Otherwise, navigate deeper into the nested structure
        currentLevel = currentLevel[part];
      }
    }

    // Update the mainCustomer state with the modified object
    setMainCustomer(updatedMainCustomer);
  };

  const onAdditionalDriverChanged = (e) => {
    const { name, value } = e.target;

    // Split the name into parts based on the dot (.) to handle nested fields
    const nameParts = name.split(".");

    // Create a copy of the current additionalDriver object to modify
    let updatedAdditionalDriver = { ...additionalDriver };

    // Use a loop to navigate through the nested structure
    let currentLevel = updatedAdditionalDriver;
    for (let i = 0; i < nameParts.length; i++) {
      const part = nameParts[i];
      if (i === nameParts.length - 1) {
        // If it's the last part, update the value
        currentLevel[part] = value;
      } else {
        // Otherwise, navigate deeper into the nested structure
        currentLevel = currentLevel[part];
      }
    }

    // Update the additionalDriver state with the modified object
    setAdditionalDriver(updatedAdditionalDriver);
  };

  const handleMainCustomerImageChange = (e) => {
    const { name, files } = e.target;

    // Ensure a file is selected before proceeding
    if (files && files[0]) {
      const updatedMainCustomer = { ...mainCustomer };

      // Store the actual file in the state
      updatedMainCustomer[name] = files[0];
      setMainCustomer(updatedMainCustomer);
    }
  };

  const handleAdditionalDriverImageChange = (e) => {
    const { name, files } = e.target;

    console.log("Selected file for", name, files[0]); // Check if the file is being selected

    if (files && files[0]) {
      const updatedAdditionalDriver = { ...additionalDriver };

      updatedAdditionalDriver[name] = files[0]; // Store the file in state
      setAdditionalDriver(updatedAdditionalDriver); // Update the state with the new file

      console.log("Updated additionalDriver:", updatedAdditionalDriver); // Check updated state
    }
  };

  const onInsuranceDetailsChanged = (e) => {
    const { name, value } = e.target;
    setInsuranceDetails((prevInsuranceDetails) => ({
      ...prevInsuranceDetails,
      [name]: value,
    }));
  };

  const onBeforeImagesChanged = (e) => setBeforeImages([e.target.value]);
  const onAfterImagesChanged = (e) => setAfterImages([e.target.value]);

  const canSave =
    [
      vehicle,
      dealership,
      pickUpLocationInDepth,
      dropOffLocationInDepth,
      startDate,
      endDate,
      startTime,
      endTime,
      dateOfBooking,
      orderNumber,
      totalPrice,
      chauffeur,
      mainCustomer,
      isManualBooking,
      complete,
    ].every((item) => item !== null && item !== undefined) && !isLoading;

  const transformedMainCustomer =
    mainCustomer.customer === "" ? null : mainCustomer.customer;

  const onSaveBookingClicked = async (e) => {
    e.preventDefault();
    if (canSave) {
      await addNewBooking({
        vehicle,
        dealership,
        pickUpLocation,
        dropOffLocation,
        pickUpLocationInDepth,
        dropOffLocationInDepth,
        startDate,
        endDate,
        startTime,
        endTime,
        dateOfBooking,
        orderNumber,
        totalPrice,
        bookingProgress,
        chauffeur,
        description,
        mainCustomer: {
          ...mainCustomer,
          customer: transformedMainCustomer,
        },
        additionalDriver,
        bookingStatus,
        isManualBooking,
        complete,
        insuranceDetails,
        beforeImages,
        afterImages,
        additionalDriverFee,
        chauffeurFee,
        deliveryFee,
        chauffeurDetails,
      });
    }
  };

  //   console.log(
  //     vehicle,
  //     dealership,
  //     pickUpLocation,
  //     dropOffLocation,
  //     pickUpLocationInDepth,
  //     dropOffLocationInDepth,
  //     startDate,
  //     endDate,
  //     startTime,
  //     endTime,
  //     dateOfBooking,
  //     orderNumber,
  //     totalPrice,
  //     chauffeur,
  //     description,
  //     mainCustomer,
  //     additionalDriver,
  //     bookingStatus,
  //     isManualBooking,
  //     complete,
  //     insuranceDetails,
  //     beforeImages,
  //     afterImages
  //   );

  const userOptions = users.map((user) => {
    // use this for the dealerhipTest
    return (
      <option key={user.id} value={user.id}>
        {" "}
        {user.email}
      </option>
    );
  });

  //   const vehicleOptions = vehicles.map((vehicle) => {
  //     // might have to change this to vehicles
  //     return (
  //       <option key={vehicle.id} value={vehicle.id}>
  //         {" "}
  //         {vehicle.registration}
  //       </option>
  //     );
  //   });

  const vehicleOptions = vehicles.map((vehicle) => (
    <option key={vehicle.id} value={vehicle.id}>
      {vehicle.registration}
    </option>
  ));

  //   console.log(vehicle);

  //   const startTimeOptions =

  //   const endTimeOptions =

  const bookingStatusOptions = [
    "Preparing Vehicle",
    "Vehicle Ready",
    "Vehicle Sent Out",
    "Awaiting Vehicle Return",
    "Vehicle Fufilled and Returned",
  ];

  // const bookingProgressOptions = [
  //   "Rejected",
  //   "To Be Confirmed",
  //   "To Be Confirmed",
  //   "Accepted Payment Not Received",
  //   "Accepted Payment Received",
  // ];

  const bookingProgressOptions = [
    "Rejected",
    "To Be Confirmed",
    "To Be Confirmed Deposit Received",
    "To be Confirmed Full Payment Received",
    "Accepted Payment Not Received",
    "Accepted Deposit Only Received",
    "Accepted Deposit Required",
    "Accepted Hire Price Excluding Deposit Required",
    "Accepted Full Payment Required",
    "Accepted Full Payment Received",
  ];

  // Filter options based on user's plan
  const getFilteredBookkingProgressOptions = (isEnterprisePlan) => {
    if (isEnterprisePlan) {
      return bookingProgressOptions; // Enterprise has access to all options
    }
    return bookingProgressOptions.filter((option) =>
      [
        "Rejected",
        "To Be Confirmed",
        "To be Confirmed Full Payment Received",
        "Accepted Payment Not Received",

        "Accepted Full Payment Received",
      ].includes(option)
    );
  };

  const filteredBookingProgressOptions =
    getFilteredBookkingProgressOptions(isEnterprisePlan);

  const timeOptions = [
    "12:00am",
    "12:30am",
    "1:00am",
    "1:30am",
    "2:00am",
    "2:30am",
    "3:00am",
    "3:30am",
    "4:00am",
    "4:30am",
    "5:00am",
    "5:30am",
    "6:00am",
    "6:30am",
    "7:00am",
    "7:30am",
    "8:00am",
    "8:30am",
    "9:00am",
    "9:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "1:00pm",
    "1:30pm",
    "2:00pm",
    "2:30pm",
    "3:00pm",
    "3:30pm",
    "4:00pm",
    "4:30pm",
    "5:00pm",
    "5:30pm",
    "6:00pm",
    "6:30pm",
    "7:00pm",
    "7:30pm",
    "8:00pm",
    "8:30pm",
    "9:00pm",
    "9:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
  ];

  const errClass = isError ? "errmsg" : "offscreen";
  const validVehicleClass = !vehicle ? "form__input--incomplete" : "";
  const validDealershipClass = !dealership ? "form__input--incomplete" : "";

  // Date Fields
  const validStartDateClass = !startDate ? "form__input--incomplete" : "";
  const validEndDateClass = !endDate ? "form__input--incomplete" : "";

  // Numeric Fields
  const validOrderNumberClass = !orderNumber ? "form__input--incomplete" : "";
  const validTotalPriceClass = !totalPrice ? "form__input--incomplete" : "";

  // Boolean Field
  const validChauffeurClass = chauffeur ? "" : "form__input--incomplete";

  // Main Customer Fields
  //   const validMainCustomerClass = !mainCustomer.customer
  //     ? "form__input--incomplete"
  //     : "";
  const validMainCustomerFullnameClass = !mainCustomer.fullname
    ? "form__input--incomplete"
    : "";
  const validDrivingLicenseClass = !mainCustomer.drivingLicense.number
    ? "form__input--incomplete"
    : "";
  const validPassportClass = !mainCustomer.passport.number
    ? "form__input--incomplete"
    : "";
  const validDateOfBirthClass = !mainCustomer.dateOfBirth
    ? "form__input--incomplete"
    : "";
  const validAddressClass = !mainCustomer.address.line1
    ? "form__input--incomplete"
    : "";

  const validExtraDetailsClass = !mainCustomer.connections.email
    ? "form__input--incomplete"
    : "";

  // Additional Driver Fields (similar to Main Customer)
  const validAdditionalDriverClass = !additionalDriver.customer
    ? "form__input--incomplete"
    : "";
  const validAdditionalDriverDrivingLicenseClass = !additionalDriver
    .drivingLicense.number
    ? "form__input--incomplete"
    : "";
  const validAdditionalDriverPassportClass = !additionalDriver.passport.number
    ? "form__input--incomplete"
    : "";
  const validAdditionalDriverDateOfBirthClass = !additionalDriver.dateOfBirth
    ? "form__input--incomplete"
    : "";
  const validAdditionalDriverAddressClass = !additionalDriver.address.line1
    ? "form__input--incomplete"
    : "";

  const validAdditionalDriverExtraDetailsClass = !mainCustomer.connections.email
    ? "form__input--incomplete"
    : "";

  //   const validDescriptionClass = !description ? "form__input--incomplete" : "";
  const validDescriptionClass = !description ? "form__input--incomplete" : "";

  // Insurance Details Fields
  const validInsuranceCompanyClass = !insuranceDetails.insuranceCompany
    ? "form__input--incomplete"
    : "";
  const validPolicyNumberClass = !insuranceDetails.policyNumber
    ? "form__input--incomplete"
    : "";
  const validValidFromClass = !insuranceDetails.validFrom
    ? "form__input--incomplete"
    : "";
  const validValidUntilClass = !insuranceDetails.validUntil
    ? "form__input--incomplete"
    : "";

  // Booking Status
  const validBookingStatusClass = !bookingStatus
    ? "form__input--incomplete"
    : "";

  // Image Fields
  const validBeforeImagesClass = !beforeImages ? "form__input--incomplete" : "";
  const validAfterImagesClass = !afterImages ? "form__input--incomplete" : "";

  // Complete Field (Boolean)
  const validCompleteClass = complete ? "" : "form__input--incomplete";

  // console.log(bookingProgress);

  const content = (
    <>
      <div className="newBookingFormErrorMsg">
        <p className={errClass}>{error?.data?.message}</p>
      </div>
      <form className="newBookingForm" onSubmit={onSaveBookingClicked}>
        <h3 className="modelAndModelVariantBookingForm">
          {selectedVehicle &&
            `${selectedVehicle.make} ${selectedVehicle.model}`}
        </h3>
        <div className="newBookingFormSaveBtn">
          <button
            className="saveBtn"
            title="save"
            disabled={!canSave}
            onClick={onSaveBookingClicked}
          >
            Book Vehicle
          </button>
        </div>
        <div className="vehicleDetailsBookingForm">
          <div className="table-container">
            <p>
              Vehicle Details (Choose the Vehicle under the payment details)
            </p>
            <table>
              <thead>
                <tr>
                  <th>Technical Details</th>
                  <th>Year</th>
                  <th>Colour</th>
                  <th>Drivetrain</th>
                  <th>Bodytype</th>
                  <th>Seats</th>
                  <th>Doors</th>
                  <th>Power</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedVehicle?.details}</td>
                  <td>{selectedVehicle?.year}</td>
                  <td>{selectedVehicle?.colour}</td>
                  <td>{selectedVehicle?.drivetrain}</td>
                  <td>{selectedVehicle?.bodyType}</td>
                  <td>{selectedVehicle?.seats}</td>
                  <td>{selectedVehicle?.doors}</td>
                  <td>{selectedVehicle?.power} Hp</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="bookingFormInputFields">
          <div className="customerDetailsBookingForm">
            <div className="sectionHeader">
              <p>Customer Details</p>
            </div>

            <div className="personalDetailsBookingForm">
              <p>Personal</p>

              <label className="form__label" htmlFor="customer">
                Customer
              </label>
              <input
                id="customer"
                name="fullname"
                type="text"
                className={`form__input__booking ${validMainCustomerFullnameClass}`}
                value={mainCustomer.fullname}
                onChange={onMainCustomerChanged}
                placeholder="Full Name"
              />
              {/* Date of Birth */}
              <label className="form__label" htmlFor="dateOfBirth">
                Date of Birth
              </label>
              <span>Date of Birth</span>
              <input
                className={`form__input__booking ${validDateOfBirthClass}`}
                id="dateOfBirth"
                name="dateOfBirth"
                type="date"
                autoComplete="off"
                value={mainCustomer.dateOfBirth}
                onChange={onMainCustomerChanged}
                placeholder="Date of Birth"
              />

              {/* Address */}
              <label className="form__label" htmlFor="address.line1">
                Address Line 1
              </label>

              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.line1"
                name="address.line1"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.line1}
                onChange={onMainCustomerChanged}
                placeholder="Address Line 1"
              />

              <label className="form__label" htmlFor="address.line2">
                Address Line 2
              </label>
              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.line2"
                name="address.line2"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.line2}
                onChange={onMainCustomerChanged}
                placeholder="Address Line 2"
              />

              <label className="form__label" htmlFor="address.city">
                City
              </label>
              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.city"
                name="address.city"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.city}
                onChange={onMainCustomerChanged}
                placeholder="City"
              />

              <label className="form__label" htmlFor="address.county">
                County
              </label>
              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.county"
                name="address.county"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.county}
                onChange={onMainCustomerChanged}
                placeholder="County"
              />

              <label className="form__label" htmlFor="address.postCode">
                Post Code
              </label>
              <input
                className={`form__input__booking ${validAddressClass}`}
                id="address.postCode"
                name="address.postCode"
                type="text"
                autoComplete="off"
                value={mainCustomer.address.postCode}
                onChange={onMainCustomerChanged}
                placeholder="Postcode"
              />
            </div>

            <div className="drivingLisenceDetails">
              <p>Driving Lisence Details</p>
              {/* Driving License */}
              <label className="form__label" htmlFor="drivingLicense.number">
                Driving License Number
              </label>
              <input
                className={`form__input__booking ${validDrivingLicenseClass}`}
                id="drivingLicense.number"
                name="drivingLicense.number"
                type="text"
                autoComplete="off"
                value={mainCustomer.drivingLicense.number}
                onChange={onMainCustomerChanged}
                placeholder="Driving License Number"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.dateOfIssue"
              >
                Date of Issue (Driving License)
              </label>
              <span>Date of Issue</span>
              <input
                className={`form__input__booking ${validDrivingLicenseClass}`}
                id="drivingLicense.dateOfIssue"
                name="drivingLicense.dateOfIssue"
                type="date"
                autoComplete="off"
                value={mainCustomer.drivingLicense.dateOfIssue}
                onChange={onMainCustomerChanged}
                placeholder="Date of Issue (Driving License)"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.dateOfExpiry"
              >
                Date of Expiry (Driving License)
              </label>
              <span>Date of Expiry</span>
              <input
                className={`form__input__booking ${validDrivingLicenseClass}`}
                id="drivingLicense.dateOfExpiry"
                name="drivingLicense.dateOfExpiry"
                type="date"
                autoComplete="off"
                value={mainCustomer.drivingLicense.dateOfExpiry}
                onChange={onMainCustomerChanged}
                placeholder="Date of Expiry (Driving License)"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.placeOfIssue"
              >
                Place of Issue (Driving License)
              </label>
              <input
                className={`form__input__booking ${validDrivingLicenseClass}`}
                id="drivingLicense.placeOfIssue"
                name="drivingLicense.placeOfIssue"
                type="text"
                autoComplete="off"
                value={mainCustomer.drivingLicense.placeOfIssue}
                onChange={onMainCustomerChanged}
                placeholder="Place of Issue (Driving License)"
              />
              {/* Front License Upload */}
              <label htmlFor="frontLicense" className="customFileUpload">
                {mainCustomer.frontDrivingLicenseImg
                  ? "Front License Uploaded"
                  : "Upload Front of License"}
              </label>
              <input
                type="file"
                id="frontLicense"
                accept="image/*"
                className="hiddenFileInput"
                name="frontDrivingLicenseImg"
                onChange={handleMainCustomerImageChange}
              />

              {/* Back License Upload */}
              <label htmlFor="backLicense" className="customFileUpload">
                {mainCustomer.backDrivingLicenseImg
                  ? "Back License Uploaded"
                  : "Upload Back of License"}
              </label>
              <input
                type="file"
                id="backLicense"
                accept="image/*"
                className="hiddenFileInput"
                name="backDrivingLicenseImg"
                onChange={handleMainCustomerImageChange}
              />
            </div>

            <div className="passportDetails">
              {/* Passport */}
              <p>Passport Details</p>
              <label className="form__label" htmlFor="passport.number">
                Passport Number
              </label>
              <input
                className={`form__input__booking ${validPassportClass}`}
                id="passport.number"
                name="passport.number"
                type="text"
                autoComplete="off"
                value={mainCustomer.passport.number}
                onChange={onMainCustomerChanged}
                placeholder="Passport Number"
              />

              <label className="form__label" htmlFor="passport.dateOfIssue">
                Date of Issue (Passport)
              </label>
              <span>Date of Issue</span>
              <input
                className={`form__input__booking ${validPassportClass}`}
                id="passport.dateOfIssue"
                name="passport.dateOfIssue"
                type="date"
                autoComplete="off"
                value={mainCustomer.passport.dateOfIssue}
                onChange={onMainCustomerChanged}
                placeholder="Date of Issue (Passport)"
              />

              <label className="form__label" htmlFor="passport.dateOfExpiry">
                Date of Expiry (Passport)
              </label>
              <span>Date of Expiry</span>
              <input
                className={`form__input__booking ${validPassportClass}`}
                id="passport.dateOfExpiry"
                name="passport.dateOfExpiry"
                type="date"
                autoComplete="off"
                value={mainCustomer.passport.dateOfExpiry}
                onChange={onMainCustomerChanged}
                placeholder="Date of Expiry (Passport)"
              />
            </div>
            <div className="extraDetailsConnections">
              <p>Extra Details</p>

              <label className="form__label" htmlFor="connections.email">
                email
              </label>
              <input
                className={`form__input__booking ${validExtraDetailsClass}`}
                id="connections.email"
                name="connections.email"
                type="text"
                autoComplete="off"
                value={mainCustomer.connections.email}
                onChange={onMainCustomerChanged}
                onFocus={(e) => {
                  if (e.target.value === "email") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "email";
                  }
                }}
                placeholder="email"
              />

              <label className="form__label" htmlFor="connections.number">
                number
              </label>
              <input
                className={`form__input__booking ${validExtraDetailsClass}`}
                id="connections.number"
                name="connections.number"
                type="number"
                autoComplete="off"
                value={mainCustomer.connections.number}
                onChange={onMainCustomerChanged}
                placeholder="number"
              />
            </div>
          </div>

          {/* just making a gap lol  */}

          <div className="bookingDetails">
            <div className="sectionHeaderBookingDetails">
              <p>Booking Details</p>
            </div>
            <div
              className={`pickUpAndDropOffLocation ${
                pickUpLocationInDepth.type === "Custom" ||
                dropOffLocationInDepth.type === "Custom"
                  ? "custom-padding"
                  : ""
              }`}
            >
              {/* <p>Pick Up Location</p>
                  <label
                    className="form__label--visually-hidden"
                    htmlFor="pickUpLocation"
                  >
                    Pick Up Location
                  </label>
                  <input
                    className={`form__input__booking ${validPickUpLocationClass}`}
                    id="pickUpLocation"
                    name="pickUpLocation"
                    type="text"
                    autoComplete="off"
                    value={pickUpLocation}
                    onChange={onPickUpLocationChanged}
                    onFocus={(e) => {
                      if (e.target.value === "pickUpLocation") {
                        e.target.value = "";
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        e.target.value = "pickUpLocation";
                      }
                    }}
                    placeholder="pickUpLocation"
                  />
    
                  <p>Drop Off Location</p>
                  <label
                    className="form__label--visually-hidden"
                    htmlFor="dropOffLocation"
                  >
                    Drop Off Location
                  </label>
                  <input
                    className={`form__input__booking ${validDropOffLocationClass}`}
                    id="dropOffLocation"
                    name="dropOffLocation"
                    type="text"
                    autoComplete="off"
                    value={dropOffLocation}
                    onChange={onDropOffLocationChanged}
                    onFocus={(e) => {
                      if (e.target.value === "dropOffLocation") {
                        e.target.value = "";
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.value === "") {
                        e.target.value = "dropOffLocation";
                      }
                    }}
                    placeholder="dropOffLocation"
                  /> */}
              <Tooltip
                id="infoTooltip"
                place="top"
                type="dark"
                effect="float"
              />

              <p>Pick Up Location Details</p>
              <label className="form__label--visually-hidden">
                Pick Up Location Type:{" "}
              </label>
              <select
                name="type"
                value={pickUpLocationInDepth.type}
                onChange={handlePickUpLocationChange}
                className={`form__input__booking ${validDealershipClass}`}
              >
                <option value="Dealership">Dealership</option>
                <option value="Custom">Custom</option>
              </select>

              {pickUpLocationInDepth.type === "Custom" && (
                <div className="customLocationNewBookingForm">
                  <input
                    type="text"
                    name="line1"
                    value={pickUpLocationInDepth.address.line1}
                    onChange={handlePickUpLocationChange}
                    placeholder="Address Line 1"
                    className={`form__input__booking `}
                  />
                  <input
                    type="text"
                    name="line2"
                    value={pickUpLocationInDepth.address.line2}
                    onChange={handlePickUpLocationChange}
                    placeholder="Address Line 2"
                    className={`form__input__booking `}
                  />
                  <input
                    type="text"
                    name="city"
                    value={pickUpLocationInDepth.address.city}
                    onChange={handlePickUpLocationChange}
                    placeholder="City"
                    className={`form__input__booking `}
                  />
                  <input
                    type="text"
                    name="county"
                    value={pickUpLocationInDepth.address.county}
                    onChange={handlePickUpLocationChange}
                    placeholder="County"
                    className={`form__input__booking `}
                  />
                  <input
                    type="text"
                    name="postCode"
                    value={pickUpLocationInDepth.address.postCode}
                    onChange={handlePickUpLocationChange}
                    placeholder="Post Code"
                    className={`form__input__booking `}
                  />
                </div>
              )}
              <p>Return Location Details</p>
              <label className="form__label--visually-hidden">
                Drop Off Location Type:{" "}
              </label>
              <select
                name="type"
                value={dropOffLocationInDepth.type}
                onChange={handleDropOffLocationChange}
                className={`form__input__booking `}
              >
                <option value="Dealership">Dealership</option>
                <option value="Custom">Custom</option>
              </select>

              {dropOffLocationInDepth.type === "Custom" && (
                <div className="customLocationNewBookingForm">
                  <input
                    type="text"
                    name="line1"
                    value={dropOffLocationInDepth.address.line1}
                    onChange={handleDropOffLocationChange}
                    placeholder="Address Line 1"
                    className={`form__input__booking`}
                  />
                  <input
                    type="text"
                    name="line2"
                    value={dropOffLocationInDepth.address.line2}
                    onChange={handleDropOffLocationChange}
                    placeholder="Address Line 2"
                    className={`form__input__booking`}
                  />
                  <input
                    type="text"
                    name="city"
                    value={dropOffLocationInDepth.address.city}
                    onChange={handleDropOffLocationChange}
                    placeholder="City"
                    className={`form__input__booking`}
                  />
                  <input
                    type="text"
                    name="county"
                    value={dropOffLocationInDepth.address.county}
                    onChange={handleDropOffLocationChange}
                    placeholder="County"
                    className={`form__input__booking`}
                  />
                  <input
                    type="text"
                    name="postCode"
                    value={dropOffLocationInDepth.address.postCode}
                    onChange={handleDropOffLocationChange}
                    placeholder="Post Code"
                    className={`form__input__booking`}
                  />
                </div>
              )}
            </div>

            <div
              className={`chauffeurDetails ${
                pickUpLocationInDepth.type === "Custom" ||
                dropOffLocationInDepth.type === "Custom"
                  ? "custom-padding"
                  : ""
              }`}
            >
              <p>Chauffeur?</p>
              <label className="form__label" htmlFor="chauffeur">
                Chauffeur Service
              </label>
              <input
                className={`form__input__booking__chauffeur ${validChauffeurClass}`}
                id="chauffeur"
                name="chauffeur"
                type="checkbox" // Set the type to "checkbox" for a boolean input
                checked={chauffeur} // Use the checked attribute to control the checkbox state
                onChange={onChauffeurChanged} // Provide an onChange handler to update the state
              />
              <p>
                Chauffeur Details (please include an accurate description of
                requirements)
              </p>
              <textarea
                className="form__input__booking__chauffeurDetails"
                id="chauffeurDetails"
                name="Chauffeur Details"
                value={chauffeurDetails}
                onChange={onChauffeurDetailsChanged}
                placeholder="Chauffeur Details"
              ></textarea>
            </div>

            <div
              className={`durationDetails ${
                pickUpLocationInDepth.type === "Custom" ||
                dropOffLocationInDepth.type === "Custom"
                  ? "custom-padding"
                  : ""
              }`}
            >
              <p>Duration</p>
              <div className="durationInputFields">
                <label className="form__label" htmlFor="startDate">
                  Start Date
                </label>
                <span>Start Date</span>
                <input
                  className={`form__input__booking ${validStartDateClass}`}
                  id="startDate"
                  name="startDate"
                  type="date" // Set the type to "date" for date input
                  value={startDate}
                  onChange={onStartDateChanged}
                  required // Add the "required" attribute for validation
                />

                <label className="form__label" htmlFor="endDate">
                  End Date
                </label>
                <span>End Date</span>
                <input
                  className={`form__input__booking ${validEndDateClass}`}
                  id="endDate"
                  name="endDate"
                  type="date" // Set the type to "date" for date input
                  value={endDate}
                  onChange={onEndDateChanged}
                  required // Add the "required" attribute for validation
                />
              </div>
            </div>
            <div
              className={`startEndDetails ${
                pickUpLocationInDepth.type === "Custom" ||
                dropOffLocationInDepth.type === "Custom"
                  ? "custom-padding"
                  : ""
              }`}
            >
              <div className="startEndInputFields">
                <p>Start Time</p>
                <label htmlFor="startTime" className="form__label">
                  Start Time:
                </label>
                <select
                  id="startTime"
                  value={startTime}
                  onChange={onStartTimeChanged}
                  className="form__input__booking"
                >
                  <option value="" disabled>
                    Please Select a Start Time
                  </option>
                  {timeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <p>End Time</p>
                <label htmlFor="endTime" className="form__label">
                  End Time:
                </label>
                <select
                  id="endTime"
                  value={endTime}
                  onChange={onEndTimeChanged}
                  className="form__input__booking"
                >
                  <option value="" disabled>
                    Please Select a Start Time
                  </option>
                  {timeOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="additionalDriverBookingForm">
            <div className="sectionHeaderAdditionalDriver">
              <p>Additional Driver Details</p>
            </div>

            <div className="personalDetailsAdditionalDriver">
              <p>Personal</p>

              <label
                className="form__label--visually-hidden"
                htmlFor="additionalCustomer"
              >
                Additional Customer
              </label>
              <input
                id="customer"
                name="customer"
                className={`form__input__booking ${validAdditionalDriverClass}`}
                type="text"
                value={additionalDriver.customer}
                onChange={onAdditionalDriverChanged}
                placeholder="Full Name"
              />

              {/* Date of Birth */}
              <label className="form__label" htmlFor="dateOfBirth">
                Date of Birth
              </label>
              <span>Date of Birth</span>
              <input
                className={`form__input__booking ${validAdditionalDriverDateOfBirthClass}`}
                id="dateOfBirth"
                name="dateOfBirth"
                type="date"
                autoComplete="off"
                value={additionalDriver.dateOfBirth}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Birth"
              />

              {/* Address */}
              <label className="form__label" htmlFor="address.line1">
                Address Line 1
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.line1"
                name="address.line1"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.line1}
                onChange={onAdditionalDriverChanged}
                placeholder="Address Line 1"
              />

              <label className="form__label" htmlFor="address.line2">
                Address Line 2
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.line2"
                name="address.line2"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.line2}
                onChange={onAdditionalDriverChanged}
                placeholder="Address Line 2"
              />

              <label className="form__label" htmlFor="address.city">
                City
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.city"
                name="address.city"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.city}
                onChange={onAdditionalDriverChanged}
                placeholder="City"
              />

              <label className="form__label" htmlFor="address.county">
                County
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.county"
                name="address.county"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.county}
                onChange={onAdditionalDriverChanged}
                placeholder="County"
              />

              <label className="form__label" htmlFor="address.postCode">
                Post Code
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverAddressClass}`}
                id="address.postCode"
                name="address.postCode"
                type="text"
                autoComplete="off"
                value={additionalDriver.address.postCode}
                onChange={onAdditionalDriverChanged}
                placeholder="Postcode"
              />
            </div>

            <div className="drivingLisenceDetailsAdditionalCustomer">
              <p>Driving Lisence Details</p>
              {/* Driving License */}
              <label className="form__label" htmlFor="drivingLicense.number">
                Driving License Number
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverDrivingLicenseClass}`}
                id="drivingLicense.number"
                name="drivingLicense.number"
                type="text"
                autoComplete="off"
                value={additionalDriver.drivingLicense.number}
                onChange={onAdditionalDriverChanged}
                placeholder="Driving License Number"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.dateOfIssue"
              >
                Date of Issue (Driving License)
              </label>
              <span>Date of Issue</span>
              <input
                className={`form__input__booking ${validAdditionalDriverDrivingLicenseClass}`}
                id="drivingLicense.dateOfIssue"
                name="drivingLicense.dateOfIssue"
                type="date"
                autoComplete="off"
                value={additionalDriver.drivingLicense.dateOfIssue}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Issue (Driving License)"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.dateOfExpiry"
              >
                Date of Expiry (Driving License)
              </label>
              <span>Date of Expiry</span>
              <input
                className={`form__input__booking ${validAdditionalDriverDrivingLicenseClass}`}
                id="drivingLicense.dateOfExpiry"
                name="drivingLicense.dateOfExpiry"
                type="date"
                autoComplete="off"
                value={additionalDriver.drivingLicense.dateOfExpiry}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Expiry (Driving License)"
              />

              <label
                className="form__label"
                htmlFor="drivingLicense.placeOfIssue"
              >
                Place of Issue (Driving License)
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverDrivingLicenseClass}`}
                id="drivingLicense.placeOfIssue"
                name="drivingLicense.placeOfIssue"
                type="text"
                autoComplete="off"
                value={additionalDriver.drivingLicense.placeOfIssue}
                onChange={onAdditionalDriverChanged}
                placeholder="Place of Issue (Driving License)"
              />
              {/* Front License Upload */}
              <label
                htmlFor="additionalDriverFrontDrivingLicenseImg"
                className="customFileUpload"
              >
                {additionalDriver.additionalDriverFrontDrivingLicenseImg
                  ? "Front License Uploaded"
                  : "Upload Front of License"}
              </label>
              <input
                type="file"
                id="additionalDriverFrontDrivingLicenseImg"
                accept="image/*"
                className="hiddenFileInput"
                name="additionalDriverFrontDrivingLicenseImg"
                onChange={handleAdditionalDriverImageChange}
              />

              {/* Back License Upload */}
              <label
                htmlFor="additionalDriverBackDrivingLicenseImg"
                className="customFileUpload"
              >
                {additionalDriver.additionalDriverBackDrivingLicenseImg
                  ? "Back License Uploaded"
                  : "Upload Back of License"}
              </label>
              <input
                type="file"
                id="additionalDriverBackDrivingLicenseImg"
                accept="image/*"
                className="hiddenFileInput"
                name="additionalDriverBackDrivingLicenseImg"
                onChange={handleAdditionalDriverImageChange}
              />
            </div>

            <div className="passportDetailsAdditionalDriver">
              <p>PassportDetails</p>
              {/* Passport */}
              <label className="form__label" htmlFor="passport.number">
                Passport Number
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverPassportClass}`}
                id="passport.number"
                name="passport.number"
                type="text"
                autoComplete="off"
                value={additionalDriver.passport.number}
                onChange={onAdditionalDriverChanged}
                placeholder="Passport Number"
              />

              <label className="form__label" htmlFor="passport.dateOfIssue">
                Date of Issue (Passport)
              </label>
              <span>Date of Issue</span>
              <input
                className={`form__input__booking ${validAdditionalDriverPassportClass}`}
                id="passport.dateOfIssue"
                name="passport.dateOfIssue"
                type="date"
                autoComplete="off"
                value={additionalDriver.passport.dateOfIssue}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Issue (Passport)"
              />

              <label className="form__label" htmlFor="passport.dateOfExpiry">
                Date of Expiry (Passport)
              </label>
              <span>Date of Expiry</span>
              <input
                className={`form__input__booking ${validAdditionalDriverPassportClass}`}
                id="passport.dateOfExpiry"
                name="passport.dateOfExpiry"
                type="date"
                autoComplete="off"
                value={additionalDriver.passport.dateOfExpiry}
                onChange={onAdditionalDriverChanged}
                placeholder="Date of Expiry (Passport)"
              />
            </div>

            <div className="extraDetailsConnectionsAdditionalDriver">
              <p>Extra Details</p>

              <label className="form__label" htmlFor="connections.email">
                email
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverExtraDetailsClass}`}
                id="connections.email"
                name="connections.email"
                type="text"
                autoComplete="off"
                value={additionalDriver.connections.email}
                onChange={onAdditionalDriverChanged}
                onFocus={(e) => {
                  if (e.target.value === "email") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "email";
                  }
                }}
                placeholder="email"
              />

              <label className="form__label" htmlFor="connections.number">
                number
              </label>
              <input
                className={`form__input__booking ${validAdditionalDriverExtraDetailsClass}`}
                id="connections.number"
                name="connections.number"
                type="number"
                autoComplete="off"
                value={additionalDriver.connections.number}
                onChange={onAdditionalDriverChanged}
                placeholder="number"
              />
            </div>
          </div>

          <div className="paymentDetails">
            <div className="paymentDetailsHeader">
              <p>Payment Details</p>
            </div>

            <div className="vehicleOption">
              <p>Vehicle</p>
              <label className="form__label--visually-hidden" htmlFor="vehicle">
                Vehicle
              </label>
              <select
                id="vehicle"
                name="vehicle"
                className={`form__input__booking ${validVehicleClass}`}
                value={vehicle}
                onChange={onVehicleChanged}
              >
                <option value="">Select a vehicle</option>{" "}
                {/* Default option */}
                {vehicleOptions}
              </select>
            </div>

            <div className="dealershipOption">
              <p>Dealership</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="dealership"
              >
                Dealership
              </label>
              <select
                id="dealership"
                name="dealership"
                className={`form__input__booking ${validDealershipClass}`}
                value={dealership}
                onChange={onDealershipChanged}
                disabled
              >
                <option value="" selected>
                  {userOptions}
                </option>
              </select>
            </div>

            <div className="orderNumber">
              <p>Order Number</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="orderNumber"
              >
                Order Number
              </label>
              <input
                className={`form__input__booking ${validOrderNumberClass}`}
                id="orderNumber"
                name="orderNumber"
                type="text"
                autoComplete="off"
                value={orderNumber}
                onChange={onOrderNumberChanged}
                onFocus={(e) => {
                  if (e.target.value === "orderNumber") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "orderNumber";
                  }
                }}
                placeholder="orderNumber"
                disabled
              />
            </div>

            <div className="totalPrice">
              <p>Chauffeur Fee</p>
              <input
                type="number"
                className="form__input__booking"
                id="Chauffeur Fee"
                name="Chauffeur Fee"
                autoComplete="off"
                value={chauffeurFee}
                onChange={onChauffeurFeeChanged}
                placeholder="Chauffeur Fee"
              />
              <p>Delivery Fee</p>
              <input
                type="number"
                className="form__input__booking"
                id="Delivery Fee"
                name="Delivery Fee"
                autoComplete="off"
                value={deliveryFee}
                onChange={onDeliveryFeeChanged}
                placeholder="Delivery Fee"
              />
              <p>Additional Driver Fee</p>
              <input
                type="number"
                className="form__input__booking"
                id="Additional Driver Fee"
                name="Additional Driver Fee"
                autoComplete="off"
                value={additionalDriverFee}
                onChange={onAdditionalDriverFeeChanged}
                placeholder="Additional Driver Fee"
              />
              <p>Total Price incl All Fees</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="totalPrice"
              >
                Vehicle TotalPrice
              </label>
              <input
                className={`form__input__booking ${validTotalPriceClass}`}
                id="totalPrice"
                name="totalPrice"
                type="number"
                autoComplete="off"
                value={totalPrice}
                onChange={onTotalPriceChanged}
                onFocus={(e) => {
                  if (e.target.value === "TotalPrice") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "TotalPrice";
                  }
                }}
                placeholder="TotalPrice"
              />
            </div>
          </div>
          <div className="insuranceDetails">
            {/* Insurance Company */}
            <div className="insuranceDetailsHeader">
              <p>Insurance Details</p>
            </div>

            <div className="insuranceCompany">
              <label className="form__label" htmlFor="insuranceCompany">
                Insurance Company
              </label>
              <p>Insurance Company</p>
              <input
                className={`form__input__booking ${validInsuranceCompanyClass}`}
                id="insuranceCompany"
                name="insuranceCompany"
                type="text"
                autoComplete="off"
                value={insuranceDetails.insuranceCompany || ""}
                onChange={onInsuranceDetailsChanged}
                placeholder="Insurance Company"
              />
            </div>

            <div className="policyNumber">
              {/* Policy Number */}
              <label className="form__label" htmlFor="policyNumber">
                Policy Number
              </label>
              <p>Policy Number</p>
              <input
                className={`form__input__booking ${validPolicyNumberClass}`}
                id="policyNumber"
                name="policyNumber"
                type="text"
                autoComplete="off"
                value={insuranceDetails.policyNumber || ""}
                onChange={onInsuranceDetailsChanged}
                placeholder="Policy Number"
              />
            </div>

            <div className="validFrom">
              {/* Valid From */}
              <label className="form__label" htmlFor="validFrom">
                Valid From
              </label>
              <p>Valid From</p>
              <input
                className={`form__input__booking ${validValidFromClass}`}
                id="validFrom"
                name="validFrom"
                type="date"
                autoComplete="off"
                value={formatDate(insuranceDetails.validFrom) || ""}
                onChange={onInsuranceDetailsChanged}
                placeholder="Valid From"
              />
            </div>

            <div className="validUntil">
              {/* Valid Until */}
              <label className="form__label" htmlFor="validUntil">
                Valid Until
              </label>
              <p>Valid Until</p>
              <input
                className={`form__input__booking ${validValidUntilClass}`}
                id="validUntil"
                name="validUntil"
                type="date"
                autoComplete="off"
                value={formatDate(insuranceDetails.validUntil) || ""}
                onChange={onInsuranceDetailsChanged}
                placeholder="Valid Until"
              />
            </div>
          </div>

          <div className="extraDetails">
            <div className="extraDetailsHeader">
              <p>Extra Details</p>
            </div>
            <div className="extraDetailsDescription">
              <label
                className="form__label--visually-hidden"
                htmlFor="description"
              >
                description
              </label>
              <p>Description</p>
              <input
                className={`form__input__booking ${validDescriptionClass}`}
                id="description"
                name="description"
                type="text"
                autoComplete="off"
                value={description || ""}
                onChange={onDescriptionChanged}
                onFocus={(e) => {
                  if (e.target.value === "description") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "description";
                  }
                }}
                placeholder="description"
              />
            </div>

            {/* Booking Status */}

            <div className="extraDetailsBookingStatus">
              <label
                className="form__label--visually-hidden"
                htmlFor="bookingStatus"
              >
                Vehicle BookingStatus
              </label>
              <p>Booking Status</p>
              <select
                className={`form__input__booking ${validBookingStatusClass}`}
                name="bookingStatus"
                value={bookingStatus}
                onChange={onBookingStatusChanged}
              >
                {bookingStatusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <p className="bookingProgressPTag">Booking Progress</p>
              <select
                className={`form__input__booking`}
                name="bookingProgress"
                value={bookingProgress}
                onChange={onBookingProgressChanged}
              >
                <option value="" disabled selected>
                  Please Select Booking Progress
                </option>
                {filteredBookingProgressOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {/* Complete  */}

            <div className="extraDetailsComplete">
              <label className="form__label" htmlFor="complete">
                Complete
              </label>
              <p>Complete?</p>
              <input
                className={`form__input__booking ${validCompleteClass}`}
                id="complete"
                name="complete"
                type="checkbox"
                checked={complete}
                onChange={onCompleteChanged}
              />
            </div>

            <div className="extraDetailsImages">
              {/* Before Images */}
              <div className="beforeImages">
                <label className="form__label" htmlFor="beforeImages">
                  Before Images
                </label>
                <p>Before Images</p>
                <input
                  className={`form__input__booking ${validBeforeImagesClass}`}
                  id="beforeImages"
                  name="beforeImages"
                  type="text"
                  autoComplete="off"
                  value={beforeImages || ""}
                  onChange={onBeforeImagesChanged}
                  placeholder="Coming Soon"
                  disabled
                />
              </div>
              <div className="space"></div> {/* Add an empty div for spacing */}
              {/* After Images */}
              <div className="afterImages">
                <label className="form__label" htmlFor="afterImages">
                  After Images
                </label>
                <p>After Images</p>
                <input
                  className={`form__input__booking ${validAfterImagesClass}`}
                  id="afterImages"
                  name="afterImages"
                  type="text"
                  autoComplete="off"
                  value={afterImages || ""}
                  onChange={onAfterImagesChanged}
                  placeholder="Coming Soon"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );

  return content;
};

export default ManualBookingForm;
