import { RxArrowLeft } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";

const VehicleDetailsPage = ({ vehicle }) => {
  const handleGoBack = () => {
    window.history.back();
  };

  const navigate = useNavigate();

  const handleBookClick = () => {
    // Redirect to the booking form with the vehicle ID as a query parameter
    navigate(`/bookings/newBooking?vehicleId=${vehicle.id}`);
  };

  const [slide, setSlide] = useState(0);

  const nextSlide = () => {
    setSlide(slide === vehicle.images.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? vehicle.images.length - 1 : slide - 1);
  };

  const content = (
    <div className="VehicleDetailsPage">
      <div className="vehicleDetailsPageHeader">
        <div className="iconAndButton">
          <button onClick={handleGoBack} className="iconAndButtonButton">
            <RxArrowLeft
              color="#0561FC"
              className="arrowLeftVehicleDetailsPage"
            />
            <span>Back</span>
          </button>
        </div>
      </div>
      <div className="vehicleDetailsPageTopHalf">
        <div className="imagesCollection">
          <FaArrowCircleLeft onClick={prevSlide} className="arrow arrow-left" />
          {vehicle.images.map((item, idx) => {
            // console.log(item);
            return (
              <img
                src={item}
                alt={item.alt}
                key={idx}
                className={slide === idx ? "slide" : "slide slide-hidden"}
              />
            );
          })}
          <FaArrowCircleRight
            onClick={nextSlide}
            className="arrow arrow-right"
          />
          <span className="indicators">
            {vehicle.images.map((_, idx) => {
              return (
                <button
                  key={idx}
                  className={
                    slide === idx ? "indicator" : "indicator indicator-inactive"
                  }
                  onClick={() => setSlide(idx)}
                ></button>
              );
            })}
          </span>
        </div>
        <div className="basicInformationCollection">
          <div className="vehicleInfoAndDayRate">
            <h3>
              {vehicle.make} {vehicle.model} {vehicle.modelVariant}
            </h3>
            <p>{vehicle.details}</p>
            <p>{vehicle.year}</p>
            <h2>
              {vehicle.pricePerDay} <span>/day</span>
            </h2>
            <h2>
              {vehicle.deposit} <span>Deposit</span>
            </h2>
          </div>
          <div className="bookButton">
            <button onClick={handleBookClick}>
              <p>Book This Car</p>
            </button>
          </div>
          <div className="dealershipInformationCollection">
            <p>{vehicle.companyName}</p>
            <img src={vehicle.profilePicture} alt="" />
          </div>
        </div>
      </div>
      <div className="vehicleDetailsPageBottomHalf">
        <div className="bookingInformation">
          <h3>Details</h3>
          <div className="detailsBottomHalf">
            <div className="justneedtomakeadiv">
              <p>{vehicle.mileageLimit} Mile Limit</p>
              <p>£{vehicle.deposit} Deposit</p>
              <p>£{vehicle.weekendPricePerDay} Weekend Price Per Day</p>
              <p>£{vehicle.weeklyPrice} Weekly Price</p>
              <p>£{vehicle.monthlyPrice} Monthly Price</p>
              <p>
                {vehicle?.chauffeurOnly ? "Chauffeur Only" : "Self Drive Hire"}
              </p>
              {vehicle.chauffeurAvailable && !vehicle.chauffeurOnly && (
                <p>Chauffeur Available</p>
              )}

              {vehicle?.allowAdditionalDriver && (
                <p>Additional Driver can be arranged</p>
              )}
              <p>Collection and Drop Arrangements can be made</p>
              <p>
                Location of Dealership -{" "}
                {vehicle.address.houseNumberAndStreetName}, {""}
                {vehicle.address.locality}, {""}
                {vehicle.address.cityOrTown}, {""}
                {vehicle.address.postcode} {""}
              </p>
            </div>
          </div>
        </div>
        <div className="vehicleInformation">
          <h3>Vehicle Details</h3>
          <div className="vehicleDetailsBottomHalf">
            <div className="needToMakeSpace">
              <p>{vehicle.details}</p>
              <p>{vehicle.year} Plate</p>
              <p>{vehicle.colour}</p>
              <p>{vehicle.drivetrain}</p>
              <p>{vehicle.seats} Seats</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return content;
};

export default VehicleDetailsPage;
