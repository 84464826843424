import { useEffect, useState } from "react";
import DealerSearchBar from "./DealerSearchBar";
import { useNavigate, Link } from "react-router-dom";
import { FaRegUserCircle, FaBars } from "react-icons/fa";
import { FaSignInAlt } from "react-icons/fa";

import useAuth from "../hooks/useAuth";

import { useSendLogoutMutation } from "../features/auth/authApiSlice";

const Header = () => {
  const navigate = useNavigate();

  const { email, isDealership } = useAuth();

  const [menuOpen, setMenuOpen] = useState(false);

  const [sendLogout, { isLoading, isSuccess, isError, error }] =
    useSendLogoutMutation();

  useEffect(() => {
    if (isSuccess) navigate("/");
  }, [isSuccess, navigate]);

  // Determine the path based on the conditions
  let linkPath = "/login"; // Default path

  if (email && email.length > 0) {
    // If email is set
    if (isDealership) {
      linkPath = "/dashboard"; // Dealership dashboard
    } else {
      linkPath = "/customerdashboard"; // Customer dashboard
    }
  }

  const linkPathDealer = "/dealershipInfo"; // Default path

  // if (isLoading) return <p>Logging Out...</p>;

  if (isError) return <p>Error: {error?.data?.message}</p>;

  // const logoutButton = (
  //   <button className="headerAccount" title="Logout" onClick={sendLogout}>
  //     <FaSignInAlt className="logOutButton" />
  //   </button>
  // );

  const logoutButton = (
    <FaSignInAlt className="logOutButton" title="Logout" onClick={sendLogout} />
  );

  const content = (
    <header className="header">
      <Link to="/">
        <h1 className="logo">IMAS</h1>
      </Link>
      {/* Search bar will go here*/}
      <DealerSearchBar />

      <FaBars className="burgerMenu" onClick={() => setMenuOpen(!menuOpen)} />

      {/* <div className="headerRight" > */}
      <div className={`headerRight ${menuOpen ? "open" : ""}`}>
        <Link to={linkPath}>
          <FaRegUserCircle
            className="headerAccount"
            style={{ color: "#adadaf", fontSize: "0.7em" }}
          />
        </Link>

        <Link to={linkPathDealer} style={{ textDecoration: "none" }}>
          <p className="headerList">List With Us </p>
        </Link>
        <Link to={linkPath} style={{ textDecoration: "none" }}>
          <p className="headerDashboard">Dashboard </p>
        </Link>
        <Link to="/" style={{ textDecoration: "none" }}>
          <p className="headerText">Hire a Car</p>
        </Link>

        {logoutButton}
      </div>
    </header>
  );

  return content;
};

export default Header;
