import {
  useGetBookingsQuery,
  selectAllBookings,
} from "../bookings/bookingsApiSlice";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { HashLoader } from "react-spinners";
import { FaSearch, FaRandom } from "react-icons/fa";

import { Link } from "react-router-dom";

function calculateDuration(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInDays = Math.round((end - start) / (1000 * 60 * 60 * 24));
  return differenceInDays + " days";
}

function wrapOrderNumber(orderNumber) {
  if (orderNumber.length > 15) {
    return (
      <span>
        {orderNumber.slice(0, 15)}
        <br />
        {orderNumber.slice(15)}
      </span>
    );
  } else {
    return orderNumber;
  }
}

const DashboardCustomerHome = () => {
  const { email, firstname, lastname } = useAuth();

  const [searchQuery, setSearchQuery] = useState("");

  const [reminders, setReminders] = useState({});

  // Reminder object structure
  // const Reminder = {
  //   bookingId: "", // Booking ID for reference
  //   message: "", // Reminder message to display
  //   daysLeft: 0, // Number of days remaining until reminder trigger
  // };

  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("newest"); // Default to newest

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Fetch bookings using the hook directly
  const {
    data: bookings,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBookingsQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (bookings && bookings.entities) {
      const { entities, ids } = bookings;

      const qualifyingBookingIds = ids.filter(
        (id) => entities[id].customer?.email === email
      ); // Filter all matching booking IDs

      const updatedReminders = []; // Array to store reminders

      qualifyingBookingIds.forEach((bookingId) => {
        const booking = entities[bookingId];

        const today = new Date();
        const startDate = new Date(booking.startDate);
        const endDate = new Date(booking.endDate);

        // Pick Up Reminder logic
        const pickUpReminderDays = Math.floor(
          (startDate - today) / (1000 * 60 * 60 * 24)
        );
        if (pickUpReminderDays <= 3 && pickUpReminderDays > 0) {
          const getPickUpLocationDetails = () => {
            if (booking.pickUpLocationInDepth.type === "Custom") {
              return `
                Address Line 1: ${
                  booking.pickUpLocationInDepth.address.line1 || "N/A"
                }
                Address Line 2: ${
                  booking.pickUpLocationInDepth.address.line2 || "N/A"
                }
                City: ${booking.pickUpLocationInDepth.address.city || "N/A"}
                County: ${booking.pickUpLocationInDepth.address.county || "N/A"}
                Postcode: ${
                  booking.pickUpLocationInDepth.address.postCode || "N/A"
                }
              `;
            } else if (booking.pickUpLocationInDepth.type === "Dealership") {
              return `
                Address Line 1: ${
                  booking.vehicleDetails.address.houseNumberAndStreetName ||
                  "N/A"
                }
                Address Line 2: ${
                  booking.vehicleDetails.address.locality || "N/A"
                }
                City: ${booking.vehicleDetails.address.cityOrTown || "N/A"}
                Postcode: ${booking.vehicleDetails.address.postcode || "N/A"}
                Country: ${booking.vehicleDetails.address.country || "N/A"}
              `;
            } else {
              return "Pick-up location details are not available.";
            }
          };

          const reminder = {
            bookingId: booking.id,
            message: `Your car booking for ${booking.vehicleDetails.make} ${
              booking.vehicleDetails.model
            } starts in ${pickUpReminderDays} days! Pick up location: ${
              booking.pickUpLocationInDepth.type
            }
          
            ${getPickUpLocationDetails()}`,
            daysLeft: pickUpReminderDays,
          };
          updatedReminders.push(reminder);
        }

        // Drop Off Reminder logic
        const dropOffReminderDays = Math.floor(
          (endDate - today) / (1000 * 60 * 60 * 24)
        );
        if (dropOffReminderDays <= 3 && dropOffReminderDays > 0) {
          const getDropOffLocationDetails = () => {
            if (booking.dropOffLocationInDepth.type === "Custom") {
              return `
                Address Line 1: ${
                  booking.dropOffLocationInDepth.address.line1 || "N/A"
                }
                Address Line 2: ${
                  booking.dropOffLocationInDepth.address.line2 || "N/A"
                }
                City: ${booking.dropOffLocationInDepth.address.city || "N/A"}
                County: ${
                  booking.dropOffLocationInDepth.address.county || "N/A"
                }
                Postcode: ${
                  booking.dropOffLocationInDepth.address.postCode || "N/A"
                }
              `;
            } else if (booking.dropOffLocationInDepth.type === "Dealership") {
              return `
                Address Line 1: ${
                  booking.vehicleDetails.address.houseNumberAndStreetName ||
                  "N/A"
                }
                Address Line 2: ${
                  booking.vehicleDetails.address.locality || "N/A"
                }
                City: ${booking.vehicleDetails.address.cityOrTown || "N/A"}
                Postcode: ${booking.vehicleDetails.address.postcode || "N/A"}
                Country: ${booking.vehicleDetails.address.country || "N/A"}
              `;
            } else {
              return "Drop-off location details are not available.";
            }
          };
          const reminder = {
            bookingId: booking.id,
            message: `Your car booking for ${booking.vehicleDetails.make} ${
              booking.vehicleDetails.model
            } starts in ${pickUpReminderDays} days! Drop off location: ${
              booking.dropOffLocationInDepth.type
            } ${getDropOffLocationDetails()}`,
            daysLeft: dropOffReminderDays,
          };
          updatedReminders.push(reminder);
        }
      });

      setReminders(updatedReminders);
    } else {
      console.log("Loading bookings or bookings data is empty");
    }
  }, [bookings, email]);

  const selectBookingsByUserEmail = useSelector((state) =>
    selectAllBookings(state).filter(
      (booking) => booking.customer?.email === email
    )
  );

  // const currentBookings = selectBookingsByUserEmail.slice(
  //   indexOfFirstItem,
  //   indexOfLastItem
  // );

  const totalPages = Math.ceil(selectBookingsByUserEmail.length / itemsPerPage);

  // UseEffect to observe bookings and potentially trigger updates
  useEffect(() => {
    // Do any actions based on bookings changes
  }, [bookings]);

  const reminderList = Object.values(reminders).filter(
    (reminder) => reminder.daysLeft <= 3
  );

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  if (isSuccess) {
    content = (
      <div className="dashboardCustomerHomePage">
        <div className="myOrdersSearchbar">
          <div className="myOrdersSearchbarDiv">
            <div className="myOrdersSearchbarIcon">
              <FaSearch />
            </div>
            <div className="myOrdersSearchbarInputField">
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>
        <div className="myHomePageH2">
          <h2>
            <p>
              Welcome, {""}
              {firstname} {lastname}!
            </p>
          </h2>
        </div>
        <h3 className="dashboardCustomerHomePageRemindersH3">Reminders</h3>
        <div className="dashboardCustomerHomePageReminders">
          {/* Display reminders if there are any */}
          {reminderList.length > 0 && (
            <ul>
              {reminderList.map((reminder) => (
                <li key={reminder.bookingId}>{reminder.message}</li>
              ))}
            </ul>
          )}
          {/* Display a message if there are no reminders */}
          {reminderList.length === 0 && (
            <p style={{ marginLeft: "20px" }}>No reminders at this time.</p>
          )}
        </div>
        <h3 className="dashboardCustomerHomePageRemindersH3">
          Why Is My Order Not Showing Up?
        </h3>
        <div className="dashboardCustomerHomePageReminders">
          <p style={{ marginLeft: "20px" }}>
            {" "}
            Once Your Booking Is Accepted The Booking Will Show On The My Orders
            Page
          </p>
        </div>

        <h3 className="dashboardCustomerHomePageRemindersH3">Recent Orders</h3>
        <div className="myOrdersMain">
          <div className="myOrdersFilterAndSearch">
            <div className="filterBtn">
              <button
                onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}
              >
                <FaRandom className="filterIconMyOrdersPageCustomer" />
                Filter
              </button>
              {filterDropdownOpen && (
                <ul
                  className={`filterDropdown ${filterDropdownOpen && "show"}`}
                >
                  <li onClick={() => setSelectedFilter("newest")}>Newest</li>
                  <li onClick={() => setSelectedFilter("oldest")}>Oldest</li>
                  <li onClick={() => setSelectedFilter("mostExpensive")}>
                    Price High-Low
                  </li>
                  <li onClick={() => setSelectedFilter("leastExpensive")}>
                    Price Low-High
                  </li>
                </ul>
              )}
            </div>
            <div className="searchBarOrdersPage">
              <FaSearch className="searchIconMyOrdersPageCustomer" />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="myOrdersData">
            <table className="myOrdersTable">
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Date</th>
                  <th>Item</th>
                  <th>Duration</th>
                  <th>Customer</th>
                  <th>Pick Up</th>
                  <th>Dropoff</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {selectBookingsByUserEmail
                  .filter((booking) => {
                    const searchTerms = searchQuery.toLowerCase().split(" ");
                    // Case-insensitive search
                    return (
                      searchTerms.length === 0 ||
                      searchTerms.some(
                        (term) =>
                          booking.id.toLowerCase().includes(term) ||
                          booking.dateOfBooking.toLowerCase().includes(term) ||
                          booking.vehicleDetails.make
                            .toLowerCase()
                            .includes(term) ||
                          booking.vehicleDetails.model
                            .toLowerCase()
                            .includes(term) ||
                          booking.vehicleDetails.modelVariant
                            .toLowerCase()
                            .includes(term) ||
                          booking.customer.firstname
                            .toLowerCase()
                            .includes(term) || // Use 'term' here
                          booking.customer.lastname
                            .toLowerCase()
                            .includes(term) || // Use 'term' here
                          booking.pickUpLocationInDepth?.type
                            .toLowerCase()
                            .includes(term) || // Use 'term' here
                          booking.dropOffLocationInDepth?.type
                            .toLowerCase()
                            .includes(term) || // Use 'term' here
                          booking.totalPrice.toString().includes(term) // Use 'term' here
                      )
                    );
                  })
                  .sort((a, b) => {
                    switch (selectedFilter) {
                      case "newest":
                        return (
                          new Date(b.dateOfBooking) - new Date(a.dateOfBooking)
                        );
                      case "oldest":
                        return (
                          new Date(a.dateOfBooking) - new Date(b.dateOfBooking)
                        );
                      case "mostExpensive":
                        return b.totalPrice - a.totalPrice;
                      case "leastExpensive":
                        return a.totalPrice - b.totalPrice;
                      default:
                        return 0;
                    }
                  })
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((booking) => (
                    <tr key={booking.id}>
                      <td className="orderNumberCell">
                        <Link to={`/customerdashboard/myOrders/${booking.id}`}>
                          {wrapOrderNumber(booking.id)}
                        </Link>
                      </td>
                      <td>{booking.dateOfBooking}</td>
                      <td>
                        {booking.vehicleDetails.make}{" "}
                        {booking.vehicleDetails.model}{" "}
                        {booking.vehicleDetails.modelVariant}
                      </td>
                      <td>
                        {booking.startDate.slice(8, 10)}-
                        {booking.startDate.slice(5, 7)}-
                        {booking.startDate.slice(0, 4)} -
                        {booking.endDate.slice(8, 10)}-
                        {booking.endDate.slice(5, 7)} -
                        {booking.endDate.slice(0, 4)}
                        {" (" +
                          calculateDuration(
                            booking.startDate,
                            booking.endDate
                          ) +
                          ")"}
                      </td>
                      <td>
                        {booking.customer.firstname} {booking.customer.lastname}
                      </td>
                      <td>{booking.pickUpLocationInDepth?.type}</td>
                      <td>{booking.dropOffLocationInDepth?.type}</td>
                      <td>£{booking.totalPrice}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => setCurrentPage(index + 1)}
                  className={index + 1 === currentPage ? "active" : ""}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default DashboardCustomerHome;
