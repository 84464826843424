import { useGetAvailableVehiclesQuery } from "./vehiclesApiSlice";
import Vehicle from "./Vehicle";
import { DateRangeContext } from "../../context/DateRangeContext";
import { useContext } from "react";

import { HashLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const VehicleList = () => {
  const {
    startDate,
    endDate,
    startTime,
    endTime,
    makeOption,
    modelOption,
    modelVariantOption,
    postcode,
    distanceRangeOption,
    priceRangeOption,
    setAvailableVehiclesCount,
  } = useContext(DateRangeContext);

  // console.log(startTime);

  const {
    data: vehicles,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAvailableVehiclesQuery(
    {
      startDate,
      endDate,
      startTime,
      endTime,
      makeOption,
      modelOption,
      modelVariantOption,
      postcode,
      distanceRangeOption,
      priceRangeOption,
    },
    {
      // pollingInterval: 120000,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  // console.log(vehicles);

  useEffect(() => {
    if (isSuccess && vehicles.ids) {
      setAvailableVehiclesCount(vehicles?.ids?.length); // Update available vehicles count in context
    }
  }, [isSuccess, vehicles, setAvailableVehiclesCount]);

  // let content;

  // if (isLoading) content = <HashLoader color="#36d7b7" />;

  // if (isError) {
  //   content = <p className="vehicleListErrMsg">{error?.data?.message}</p>;
  // }

  // if (isSuccess) {
  //   const { ids } = vehicles;

  //   const vehicleList = ids?.length
  //     ? ids.map((vehicleId) => (
  //         <div key={vehicleId}>
  //           <Link to={`/vehicle/${vehicleId}`}>
  //             <Vehicle key={vehicleId} vehicleId={vehicleId} />
  //           </Link>
  //         </div>
  //       ))
  //     : null;

  //   content = <div className="vehicleListGrid">{vehicleList}</div>;
  // }

  // return content;

  let contentInside;
  if (isLoading) contentInside = <HashLoader color="#36d7b7" />;
  if (isError) {
    contentInside = <p className="vehicleListErrMsg">{error?.data?.message}</p>;
  }
  if (isSuccess) {
    const { ids } = vehicles;
    const vehicleList = ids?.length ? (
      ids.map((vehicleId) => (
        <div key={vehicleId}>
          <Link to={`/vehicle/${vehicleId}`}>
            <Vehicle key={vehicleId} vehicleId={vehicleId} />
          </Link>
        </div>
      ))
    ) : (
      <p>No vehicles available matching your criteria.</p>
    );
    contentInside = <div className="vehicleListGrid">{vehicleList}</div>;
  }

  // Always return a container with a minimum height
  return (
    <div className="vehicleListContainer" style={{ minHeight: "160vh" }}>
      {contentInside}
    </div>
  );
};

export default VehicleList;
