import { useState, useEffect } from "react";
import {
  useUpdateVehicleMutation,
  useDeleteVehicleMutation,
} from "./vehiclesApiSlice";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import useAuth from "../../hooks/useAuth";

const EditVehicleForm = ({ vehicle, users }) => {
  const [updateVehicle, { isLoading, isSuccess, isError, error }] =
    useUpdateVehicleMutation();

  const [
    deleteVehicle,
    { isSuccess: isDelSuccess, isError: isDelError, error: delerror },
  ] = useDeleteVehicleMutation();

  const navigate = useNavigate();

  const { stripeOnboardingComplete, isEnterprisePlan } = useAuth();

  const [userId, setUserId] = useState(vehicle.owner); // or vehicle.user
  const [make, setMake] = useState(vehicle.make);
  const [model, setModel] = useState(vehicle.model);
  const [modelVariant, setModelVariant] = useState(vehicle.modelVariant);
  const [details, setDetails] = useState(vehicle.details); // this field is optional
  const [year, setYear] = useState(vehicle.year);
  const [pricePerDay, setPricePerDay] = useState(vehicle.pricePerDay);
  const [registration, setRegistration] = useState(vehicle.registration);
  const [drivetrain, setDrivetrain] = useState(vehicle.drivetrain); // ["rear-wheel drive", "four-wheel drive", "front-wheel drive"]
  const [bodyType, setBodyType] = useState(vehicle.bodyType); // [ "convertible", "coupe", "estate", "hatchback", "limo", "suv", "saloon",]
  const [doors, setDoors] = useState(vehicle.doors);
  const [seats, setSeats] = useState(vehicle.seats);
  const [power, setPower] = useState(vehicle.power);
  const [torque, setTorque] = useState(vehicle.torque);

  const [chauffeurOnly, setChauffeurOnly] = useState(
    vehicle?.chauffeurOnly || false
  );
  const [allowAdditionalDriver, setAllowAdditionalDriver] = useState(
    vehicle?.allowAdditionalDriver || false
  );

  const [images, setImages] = useState(vehicle.images);
  const [slide, setSlide] = useState(0);
  const [imagesUrl, setImagesUrl] = useState([]);
  // const [location, setLocation] = useState({
  //   latitude: vehicle.location.latitude,
  //   longitude: vehicle.location.longitude,
  // });
  const [address, setAddress] = useState({
    houseNumberAndStreetName: vehicle.address
      ? vehicle.address.houseNumberAndStreetName
      : "",
    locality: vehicle.address ? vehicle.address.locality : "",
    cityOrTown: vehicle.address ? vehicle.address.cityOrTown : "",
    postcode: vehicle.address ? vehicle.address.postcode : "",
    country: vehicle.address ? vehicle.address.country : "United Kingdom", // Assuming default is UK
  });

  const [delivery, setDelivery] = useState({
    acceptHomeDelivery: vehicle.delivery
      ? vehicle.delivery.acceptHomeDelivery
      : false,
    acceptPickUpFromDealership: vehicle.delivery
      ? vehicle.delivery.acceptPickUpFromDealership
      : true,
    homeDeliveryMaximumMileage: vehicle.delivery
      ? vehicle.delivery.homeDeliveryMaximumMileage
      : "",
    homeDeliveryChargePricePerMile: vehicle.delivery
      ? vehicle.delivery.homeDeliveryChargePricePerMile
      : "",
  });

  const [available, setAvailable] = useState(vehicle.available);
  const [owner, setOwner] = useState(vehicle.owner);
  const [mileage, setMileage] = useState(vehicle.mileage);
  const [status, setStatus] = useState(vehicle.status); // ["Good", "Major Damage", "Minor Damage"]
  const [notes, setNotes] = useState(vehicle.notes); // this field is optional
  const [colour, setColour] = useState(vehicle.colour);
  const [mileageLimit, setMileageLimit] = useState(vehicle.mileageLimit);
  const [deposit, setDeposit] = useState(vehicle.deposit);
  const [additionalDriverPricePerDay, setAdditionalDriverPricePerDay] =
    useState(vehicle.additionalDriverPricePerDay);
  const [chauffeurAvailable, setChauffeurAvailable] = useState(
    vehicle.chauffeurAvailable
  );
  const [chauffeurPricePerDay, setChauffeurPricePerDay] = useState(
    vehicle.chauffeurPricePerDay
  );
  const [minimumAge, setMinimumAge] = useState(vehicle.minimumAge);

  const [availability, setAvailability] = useState({
    startTimes: vehicle?.availability?.startTimes || [],
    endTimes: vehicle?.availability?.endTimes || [],
    daysOfWeekDealershipOpenForThisVehicle:
      vehicle?.availability?.daysOfWeekDealershipOpenForThisVehicle || [],
  });

  // console.log(availability);

  const [sameDayReturnAndStart, setSameDayReturnAndStart] = useState(() => ({
    acceptSameDayReturnAndStart:
      vehicle?.sameDayReturnAndStart?.acceptSameDayReturnAndStart || false,
    minGapBetweenBookings:
      vehicle?.sameDayReturnAndStart?.minGapBetweenBookings || 0,
  }));

  const [fullPaymentRequired, setFullPaymentRequired] = useState(
    vehicle?.fullPaymentRequired || false
  );
  const [depositRequired, setDepositRequired] = useState(
    vehicle?.depositRequired || false
  );
  const [weekendPricePerDay, setWeekendPricePerDay] = useState(
    vehicle?.weekendPricePerDay
  );
  const [weeklyPrice, setWeeklyPrice] = useState(vehicle?.weeklyPrice);
  const [monthlyPrice, setMonthlyPrice] = useState(vehicle?.monthlyPrice);

  const nextSlide = () => {
    setSlide(slide === imagesUrl.length - 1 ? 0 : slide + 1);
  };

  const prevSlide = () => {
    setSlide(slide === 0 ? imagesUrl.length - 1 : slide - 1);
  };

  // console.log(delivery);

  useEffect(() => {
    if (isSuccess || isDelSuccess) {
      setUserId("");
      setMake("");
      setModel("");
      setModelVariant("");
      setDetails("");
      setYear("");
      setPricePerDay("");
      setRegistration("");
      setDrivetrain("four-wheel drive");
      setImagesUrl("");
      setBodyType("convertible");
      // setDrivetrain(["four-wheel drive"]);
      // setBodyType(["convertible"]);
      setDoors("");
      setSeats("");
      setPower("");
      setTorque("");
      setImages([]);
      // setLocation({
      //   latitude: "",
      //   longitude: "",
      // });
      setAddress({
        houseNumberAndStreetName: "",
        locality: "",
        cityOrTown: "",
        postcode: "",
        country: "",
      });
      setDelivery({
        acceptHomeDelivery: false,
        acceptPickUpFromDealership: true,
        homeDeliveryMaximumMileage: "",
        homeDeliveryChargePricePerMile: "",
      });
      setSameDayReturnAndStart({
        acceptSameDayReturnAndStart: false,
        minGapBetweenBookings: "",
      });
      setAvailable(false);
      setChauffeurOnly(false);
      setAllowAdditionalDriver(false);
      setOwner(userId);
      setMileage("");
      setStatus("Good");
      setNotes("");
      setColour("");
      setMileageLimit("");
      setDeposit("");
      setAdditionalDriverPricePerDay("");
      setChauffeurAvailable(false);
      setChauffeurPricePerDay("");
      setMinimumAge("");
      setAvailability({
        startTimes: [],
        endTimes: [],
        daysOfWeekDealershipOpenForThisVehicle: [],
      });
      setFullPaymentRequired(false);
      setDepositRequired(false);
      setWeekendPricePerDay("");
      setWeeklyPrice("");
      setMonthlyPrice("");
      navigate("/dashboard/myVehicles");
    }
  }, [isSuccess, isDelSuccess, navigate, userId]);

  const onMakeChanged = (e) => setMake(e.target.value);
  const onModelChanged = (e) => setModel(e.target.value);
  const onModelVariantChanged = (e) => setModelVariant(e.target.value);
  const onDetailsChanged = (e) => setDetails(e.target.value);
  const onYearChanged = (e) => setYear(e.target.value);
  const onPricePerDayChanged = (e) => setPricePerDay(e.target.value);
  const onRegistrationChanged = (e) => setRegistration(e.target.value);
  const onDrivetrainChanged = (e) => setDrivetrain(e.target.value);
  const onBodyTypeChanged = (e) => setBodyType(e.target.value);
  const onDoorsChanged = (e) => setDoors(e.target.value);
  const onSeatsChanged = (e) => setSeats(e.target.value);
  const onPowerChanged = (e) => setPower(e.target.value);
  const onTorqueChanged = (e) => setTorque(e.target.value);

  const onDeliveryChanged = (e) => {
    const { name, type, value, checked } = e.target;

    setDelivery((prevState) => ({
      ...prevState,
      [name]:
        type === "checkbox"
          ? checked
          : type === "number"
          ? Number(value)
          : value,
    }));
  };

  // const onBooleanDeliveryChanged = (e) => {
  //   const { name, checked } = e.target;

  //   setDelivery((prevState) => ({
  //     ...prevState,
  //     [name]: checked, // Update the boolean value based on the checkbox
  //   }));
  // };

  // const onNumericDeliveryChanged = (e) => {
  //   const { name, value } = e.target;

  //   setDelivery((prevState) => ({
  //     ...prevState,
  //     [name]: value === "" ? "" : Number(value), // Update the number value, or keep it as an empty string if blank
  //   }));
  // };

  // const onNumericDeliveryChanged = (e) => {
  //   const { name, value } = e.target;

  //   setDelivery((prevState) => ({
  //     ...prevState,
  //     [name]: value, // Keep the value as a string
  //   }));
  // };

  // const onNumericDeliveryChanged = (e) => {
  //   const { name, value } = e.target;
  //   if (value !== delivery.homeDeliveryMaximumMileage) {
  //     setDelivery((prevState) => ({
  //       ...prevState,
  //       [name]: value,
  //     }));
  //   }
  // };

  const onImagesChanged = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      // Convert files to an array of file objects
      const fileObjects = Array.from(files);
      // Now you can do whatever you want with the fileObjects array
      console.log("Selected files:", fileObjects);
      // For example, you can set them in state

      // Generate URLs for the selected files
      const fileUrls = fileObjects.map((file) => URL.createObjectURL(file));

      setImages(fileObjects);
      setImagesUrl(fileUrls);
    }
  };

  const onAvailableChanged = (e) => {
    setAvailable(e.target.checked);
  };

  const onOwnerChanged = (e) => setOwner(e.target.value); // may not need this
  const onMileageChanged = (e) => setMileage(e.target.value);
  const onStatusChanged = (e) => setStatus(e.target.value);
  const onNotesChanged = (e) => setNotes(e.target.value);
  const onColourChanged = (e) => setColour(e.target.value);
  const onMileageLimitChanged = (e) => setMileageLimit(e.target.value);
  const onDepositChanged = (e) => setDeposit(e.target.value);
  const onAdditionalDriverPricePerDay = (e) =>
    setAdditionalDriverPricePerDay(e.target.value);
  const onChauffeurAvailableChanged = (e) =>
    setChauffeurAvailable(e.target.checked);
  const onChauffeurPricePerDayChanged = (e) =>
    setChauffeurPricePerDay(e.target.value);

  const onMinimumAgeChanged = (e) => setMinimumAge(e.target.value);
  // const onLocationChanged = (e) => {
  //   const { name, value } = e.target;
  //   setLocation((prevLocation) => ({
  //     ...prevLocation,
  //     [name]: value,
  //   }));
  // };
  const onAddressChanged = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  // const onPaymentChange = (e) => {
  //   setFullPaymentRequired(e.target.checked);
  // };

  const onPaymentChange = (e) => {
    setFullPaymentRequired(e.target.checked);
    if (e.target.checked) {
      setDepositRequired(false); // Uncheck depositRequired if fullPaymentRequired is checked
    }
  };

  const onDepositRequiredChange = (e) => {
    setDepositRequired(e.target.checked);
    if (e.target.checked) {
      setFullPaymentRequired(false); // Uncheck fullPaymentRequired if depositRequired is checked
    }
  };

  const onChauffeurOnlyChanged = (e) => {
    setChauffeurOnly(e.target.checked);
  };

  const onAllowAdditionalDriverChanged = (e) => {
    setAllowAdditionalDriver(e.target.checked);
  };

  const onWeekendPricePerDayChanged = (e) =>
    setWeekendPricePerDay(e.target.value);
  const onWeeklyPriceChanged = (e) => setWeeklyPrice(e.target.value);
  const onMonthlyPriceChanged = (e) => setMonthlyPrice(e.target.value);

  // const onAvailabilityChanged = (e) => {
  //   const { name, value, checked } = e.target;
  //   if (checked) {
  //     setAvailability((prevAvailability) => ({
  //       ...prevAvailability,
  //       [name]: [...prevAvailability[name], value],
  //     }));
  //   } else {
  //     setAvailability((prevAvailability) => ({
  //       ...prevAvailability,
  //       [name]: prevAvailability[name].filter((item) => item !== value),
  //     }));
  //   }
  // };

  // const onAvailabilityChanged = (e) => {
  //   const { name, value, checked } = e.target;

  //   setAvailability((prevAvailability) => {
  //     const updatedArray = checked
  //       ? [...prevAvailability[name], value]
  //       : prevAvailability[name].filter((item) => item !== value);

  //     return {
  //       ...prevAvailability,
  //       [name]: updatedArray,
  //     };
  //   });
  // };

  // const compareTimes = (time1, time2) => {
  //   let hour1 = parseInt(time1.split(":")[0]);
  //   const minute1 = parseInt(time1.split(":")[1].slice(0, 2)); // Get first two chars of minutes (am/pm)
  //   const isAM1 = time1.includes("am"); // Check for AM/PM

  //   let hour2 = parseInt(time2.split(":")[0]);
  //   const minute2 = parseInt(time2.split(":")[1].slice(0, 2));
  //   const isAM2 = time2.includes("am");

  //   // Prioritize AM times
  //   if (isAM1 && !isAM2) return -1;
  //   if (!isAM1 && isAM2) return 1;

  //   // Compare hours (considering AM/PM)
  //   if (isAM1 && isAM2) {
  //     if (hour1 < hour2) return -1;
  //     if (hour1 > hour2) return 1;
  //   } else if (!isAM1 && !isAM2) {
  //     // Handle PM times (add 12 for comparison)
  //     hour1 = (hour1 + 12) % 24; // Adjust for PM hours
  //     hour2 = (hour2 + 12) % 24;

  //     if (hour1 < hour2) return -1;
  //     if (hour1 > hour2) return 1;
  //   }

  //   // Compare minutes if hours are equal
  //   if (minute1 < minute2) return -1;
  //   if (minute1 > minute2) return 1;
  //   return 0; // Times are equal
  // };

  const compareTimes = (time1, time2) => {
    // Extract hours and minutes from time strings
    const [time1Hour, time1MinutePart] = time1.split(":");
    const minute1 = parseInt(time1MinutePart.slice(0, 2));
    const isAM1 = time1.includes("am");

    const [time2Hour, time2MinutePart] = time2.split(":");
    const minute2 = parseInt(time2MinutePart.slice(0, 2));
    const isAM2 = time2.includes("am");

    // Convert 12 AM and 12 PM to 0 and 12 respectively for comparison
    let hour1 = parseInt(time1Hour);
    if (hour1 === 12) {
      hour1 = isAM1 ? 0 : 12;
    } else {
      hour1 = isAM1 ? hour1 : hour1 + 12;
    }

    let hour2 = parseInt(time2Hour);
    if (hour2 === 12) {
      hour2 = isAM2 ? 0 : 12;
    } else {
      hour2 = isAM2 ? hour2 : hour2 + 12;
    }

    // Compare hours
    if (hour1 < hour2) return -1;
    if (hour1 > hour2) return 1;

    // If hours are equal, compare minutes
    if (minute1 < minute2) return -1;
    if (minute1 > minute2) return 1;

    return 0; // Times are equal
  };

  const daysOrder = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const compareDays = (day1, day2) => {
    return daysOrder.indexOf(day1) - daysOrder.indexOf(day2);
  };

  // const onAvailabilityChanged = (e) => {
  //   const { name, value, checked } = e.target;

  //   setAvailability((prevAvailability) => {
  //     const updatedArray = checked
  //       ? [...prevAvailability[name], value].sort(compareTimes) // Sort after adding/removing
  //       : prevAvailability[name].filter((item) => item !== value);

  //     return {
  //       ...prevAvailability,
  //       [name]: updatedArray,
  //     };
  //   });
  // };

  const onAvailabilityChanged = (e) => {
    const { name, value, checked } = e.target;

    setAvailability((prevAvailability) => {
      const updatedArray = checked
        ? [...prevAvailability[name], value].sort(
            name === "daysOfWeekDealershipOpenForThisVehicle"
              ? compareDays
              : compareTimes
          )
        : prevAvailability[name].filter((item) => item !== value);

      return {
        ...prevAvailability,
        [name]: updatedArray,
      };
    });
  };

  // console.log(delivery);

  const onSameDayReturnAndStartChange = (e) => {
    const { name, value, type, checked } = e.target;

    setSameDayReturnAndStart((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : parseInt(value),
    }));
  };

  // const onUserIdChanged = (e) => setUserId(e.target.value);

  const canSave =
    [
      userId,
      make,
      model,
      modelVariant,
      year,
      pricePerDay,
      registration,
      drivetrain,
      bodyType,
      doors,
      seats,
      power,
      torque,
      images,
      // available,
      owner,
      mileage,
      status,
      colour,
      mileageLimit,
      deposit,
      additionalDriverPricePerDay,
      minimumAge,
      // chauffeurAvailable,
      // chauffeurPricePerDay,
      // location,
      address,
      availability,
      sameDayReturnAndStart,
      delivery,
      // fullPaymentRequired,
      // depositRequired,
      typeof fullPaymentRequired === "boolean",
      typeof depositRequired === "boolean",
      typeof chauffeurOnly === "boolean",
      typeof allowAdditionalDriver === "boolean",
      weekendPricePerDay,
      weeklyPrice,
      monthlyPrice,
    ].every(Boolean) && !isLoading;

  const onSaveVehicleClicked = async (e) => {
    e.preventDefault();

    if (canSave) {
      await updateVehicle({
        owner: userId,
        make,
        model,
        modelVariant,
        year,
        pricePerDay,
        registration,
        drivetrain,
        bodyType,
        doors,
        seats,
        power,
        torque,
        images,
        available,
        mileage,
        status,
        colour,
        mileageLimit,
        deposit,
        additionalDriverPricePerDay,
        chauffeurAvailable,
        chauffeurPricePerDay,
        minimumAge,
        // location,
        address,
        notes,
        details,
        availability,
        sameDayReturnAndStart,
        delivery,
        fullPaymentRequired,
        chauffeurOnly,
        allowAdditionalDriver,
        depositRequired,
        weekendPricePerDay,
        weeklyPrice,
        monthlyPrice,
        id: vehicle.id,
        user: userId,
      });
    }
  };

  // const onDeleteVehicleClicked = async () => {
  //   await deleteVehicle({ id: vehicle.id });
  // };

  const options = users.map((user) => {
    return (
      <option key={user.id} value={user.id}>
        {" "}
        {user.email}
      </option>
    );
  });
  const driveTrainOptions = [
    "rear-wheel drive",
    "four-wheel drive",
    "front-wheel drive",
  ];
  const bodyTypeOptions = [
    "Convertible",
    "Coupe",
    "Estate",
    "Hatchback",
    "Limo",
    "Suv",
    "Saloon",
  ];

  // Time Options
  // const timeOptions = [
  //   "0-1am",
  //   "1-2am",
  //   "2-3am",
  //   "3-4am",
  //   "4-5am",
  //   "5-6am",
  //   "6-7am",
  //   "7-8am",
  //   "8-9am",
  //   "9-10am",
  //   "10-11am",
  //   "11-12pm",
  //   "12-1pm",
  //   "1-2pm",
  //   "2-3pm",
  //   "3-4pm",
  //   "4-5pm",
  //   "5-6pm",
  //   "6-7pm",
  //   "7-8pm",
  //   "8-9pm",
  //   "9-10pm",
  //   "10-11pm",
  //   "11-12am",
  // ];

  // Time Options
  const timeOptions = [
    "12:00am",
    "12:30am",
    "1:00am",
    "1:30am",
    "2:00am",
    "2:30am",
    "3:00am",
    "3:30am",
    "4:00am",
    "4:30am",
    "5:00am",
    "5:30am",
    "6:00am",
    "6:30am",
    "7:00am",
    "7:30am",
    "8:00am",
    "8:30am",
    "9:00am",
    "9:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "1:00pm",
    "1:30pm",
    "2:00pm",
    "2:30pm",
    "3:00pm",
    "3:30pm",
    "4:00pm",
    "4:30pm",
    "5:00pm",
    "5:30pm",
    "6:00pm",
    "6:30pm",
    "7:00pm",
    "7:30pm",
    "8:00pm",
    "8:30pm",
    "9:00pm",
    "9:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
  ];

  // Days of the Week Options
  const daysOfWeekOptions = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const vehicleStatusOptions = ["Good", "Major Damage", "Minor Damage"];

  const errClass = isError || isDelError ? "errmsg" : "offscreen";
  const validMakeClass = !make ? "form__input--incomplete" : "";
  const validModelClass = !model ? "form__input--incomplete" : "";
  const validModelVariantClass = !modelVariant ? "form__input--incomplete" : "";
  const validDetailsClass = !details ? "form__input--incomplete" : "";
  const validYearClass = !year ? "form__input--incomplete" : "";
  const validPricePerDayClass = !pricePerDay ? "form__input--incomplete" : "";
  const validRegistrationClass = !registration ? "form__input--incomplete" : "";
  const validDrivetrainClass = !drivetrain ? "form__input--incomplete" : "";
  const validBodyTypeClass = !bodyType ? "form__input--incomplete" : "";
  const validDoorsClass = !doors ? "form__input--incomplete" : "";
  const validSeatsClass = !seats ? "form__input--incomplete" : "";
  const validPowerClass = !power ? "form__input--incomplete" : "";
  const validTorqueClass = !torque ? "form__input--incomplete" : "";

  const validDeliveryClass = !delivery ? "form__input--incomplete" : "";

  // const validImagesClass = !images ? "form__input--incomplete" : "";
  const validAvailableClass = !available ? "form__input--incomplete" : "";
  const validOwnerClass = !owner ? "form__input--incomplete" : "";
  const validMileageClass = !mileage ? "form__input--incomplete" : "";
  const validStatusClass = !status ? "form__input--incomplete" : "";
  const validNotesClass = !notes ? "form__input--incomplete" : "";
  const validColourClass = !colour ? "form__input--incomplete" : "";
  const validMileageLimitClass = !mileageLimit ? "form__input--incomplete" : "";
  const validDepositClass = !deposit ? "form__input--incomplete" : "";
  const validAdditionalDriverPricePerDayClass = !additionalDriverPricePerDay
    ? "form__input--incomplete"
    : "";
  const validChauffeurAvailableClass = !chauffeurAvailable
    ? "form__input--incomplete"
    : "";
  const validChauffeurPricePerDayClass = !chauffeurPricePerDay
    ? "form__input--incomplete"
    : "";
  const validMinimumAgeClass = !minimumAge ? "form__input--incomplete" : "";

  const validhouseNumberAndStreetNameClass = address.houseNumberAndStreetName
    ? ""
    : "form__input--incomplete";
  const validlocalityClass = address.locality ? "" : "form__input--incomplete";
  const validcityOrTownClass = address.cityOrTown
    ? ""
    : "form__input--incomplete";
  const validpostcodeClass = address.postcode ? "" : "form__input--incomplete";

  // const validAcceptSameDayReturnAndStartClass = !sameDayReturnAndStart.acceptSameDayReturnAndStart ? "form__input--incomplete" : "";

  // const validStartTimesClass = availability.startTimes
  //   ? ""
  //   : "form__input--incomplete";
  // const validEndTimesClass = availability.endTimes
  //   ? ""
  //   : "form__input--incomplete";
  // const validDaysOfWeekDealershipOpenForThisVehicle =
  //   availability.daysOfWeekDealershipOpenForThisVehicle
  //     ? ""
  //     : "form__input--incomplete";

  const errContent = (error?.data?.message || delerror?.data?.message) ?? "";

  const content = (
    <>
      <div className="newBookingFormErrorMsg">
        <p className={errClass}>{errContent}</p>
      </div>
      <form className="newVehicleForm" onSubmit={onSaveVehicleClicked}>
        <h1>Edit Vehicle</h1>
        <div className="editVehicleFormSaveBtn">
          <button
            className="saveBtn"
            title="save"
            disabled={!canSave}
            onClick={onSaveVehicleClicked}
          >
            Save
          </button>
          {/* <button
            className="delBtn"
            title="del"
            onClick={onDeleteVehicleClicked}
            disabled
          >
            Delete
          </button> */}
        </div>
        <div className="newVehicleFormDivs">
          <div className="newVehicleFormVehicleDetails">
            <div className="newVehicleFormVehicleDetailsColumn">
              <p className="newVehicleFormPTag">Vehicle Details</p>
              <label className="form__label--visually-hidden" htmlFor="make">
                Vehicle Make
              </label>
              <input
                className={`form__input__vehicle ${validMakeClass}`}
                id="make"
                name="make"
                type="text"
                autoComplete="off"
                value={make}
                onChange={onMakeChanged}
                onFocus={(e) => {
                  if (e.target.value === "Make") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Make";
                  }
                }}
                placeholder="Make"
              />
              <label className="form__label--visually-hidden" htmlFor="model">
                Vehicle Model
              </label>
              <input
                className={`form__input__vehicle ${validModelClass}`}
                id="model"
                name="model"
                type="text"
                autoComplete="off"
                value={model}
                onChange={onModelChanged}
                onFocus={(e) => {
                  if (e.target.value === "Model") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Model";
                  }
                }}
                placeholder="Model"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="modelVariant"
              >
                Vehicle Model Variant
              </label>
              <input
                className={`form__input__vehicle ${validModelVariantClass}`}
                id="modelVariant"
                name="modelVariant"
                type="text"
                autoComplete="off"
                value={modelVariant}
                onChange={onModelVariantChanged}
                onFocus={(e) => {
                  if (e.target.value === "Model Variant") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Model Variant";
                  }
                }}
                placeholder="Model Variant"
              />
              <label className="form__label--visually-hidden" htmlFor="details">
                Vehicle Details
              </label>
              <input
                className={`form__input__vehicle ${validDetailsClass}`}
                id="details"
                name="details"
                type="text"
                autoComplete="off"
                value={details}
                onChange={onDetailsChanged}
                onFocus={(e) => {
                  if (e.target.value === "Details") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Details";
                  }
                }}
                placeholder="Details"
              />
              <label className="form__label--visually-hidden" htmlFor="year">
                Vehicle Year
              </label>
              <input
                className={`form__input__vehicle ${validYearClass}`}
                id="year"
                name="year"
                type="number"
                autoComplete="off"
                value={year}
                onChange={onYearChanged}
                onFocus={(e) => {
                  if (e.target.value === "Year") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Year";
                  }
                }}
                placeholder="Year"
              />
            </div>
            <div
              className="newVehicleFormVehicleDetailsColumn"
              style={{ marginTop: "20px" }}
            >
              <label
                className="form__label--visually-hidden"
                htmlFor="registration"
              >
                Vehicle Registration
              </label>
              <input
                className={`form__input__vehicle ${validRegistrationClass}`}
                id="registration"
                name="registration"
                type="text"
                autoComplete="off"
                value={registration}
                onChange={onRegistrationChanged}
                onFocus={(e) => {
                  if (e.target.value === "Registration") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Registration";
                  }
                }}
                placeholder="Registration"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="drivetrain"
              >
                Vehicle Drivetrain
              </label>
              <p className="newVehicleFormDrivetrain">Drivetrain</p>
              <select
                className={`form__input__vehicle ${validDrivetrainClass}`}
                name="drivetrain"
                value={drivetrain}
                onChange={onDrivetrainChanged}
              >
                {driveTrainOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <label
                className="form__label--visually-hidden"
                htmlFor="bodytype"
              >
                Vehicle Bodytype
              </label>
              <p className="newVehicleFormDrivetrain">Bodytype</p>
              <select
                className={`form__input__vehicle ${validBodyTypeClass}`}
                name="bodytype"
                value={bodyType}
                onChange={onBodyTypeChanged}
              >
                {bodyTypeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <label className="form__label--visually-hidden" htmlFor="doors">
                Vehicle Doors
              </label>
              <input
                className={`form__input__vehicle ${validDoorsClass}`}
                id="doors"
                name="doors"
                type="number"
                autoComplete="off"
                value={doors}
                onChange={onDoorsChanged}
                onFocus={(e) => {
                  if (e.target.value === "Doors") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Doors";
                  }
                }}
                placeholder="Doors"
              />
              <label className="form__label--visually-hidden" htmlFor="seats">
                Vehicle Seats
              </label>
              <input
                className={`form__input__vehicle ${validSeatsClass}`}
                id="seats"
                name="seats"
                type="number"
                autoComplete="off"
                value={seats}
                onChange={onSeatsChanged}
                onFocus={(e) => {
                  if (e.target.value === "Seats") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Seats";
                  }
                }}
                placeholder="Seats"
              />
            </div>

            <div
              className="newVehicleFormVehicleDetailsColumn"
              style={{ marginTop: "20px" }}
            >
              <label className="form__label--visually-hidden" htmlFor="power">
                Vehicle Power
              </label>
              <input
                className={`form__input__vehicle ${validPowerClass}`}
                id="power"
                name="power"
                type="number"
                autoComplete="off"
                value={power}
                onChange={onPowerChanged}
                onFocus={(e) => {
                  if (e.target.value === "Power") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Power";
                  }
                }}
                placeholder="Power"
              />
              <label className="form__label--visually-hidden" htmlFor="torque">
                Vehicle Torque
              </label>
              <input
                className={`form__input__vehicle ${validTorqueClass}`}
                id="torque"
                name="torque"
                type="number"
                autoComplete="off"
                value={torque}
                onChange={onTorqueChanged}
                onFocus={(e) => {
                  if (e.target.value === "Torque") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Torque";
                  }
                }}
                placeholder="Torque"
              />
              <label className="form__label--visually-hidden" htmlFor="colour">
                Vehicle Colour
              </label>
              <input
                className={`form__input__vehicle ${validColourClass}`}
                id="colour"
                name="colour"
                type="text"
                autoComplete="off"
                value={colour}
                onChange={onColourChanged}
                onFocus={(e) => {
                  if (e.target.value === "Colour") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Colour";
                  }
                }}
                placeholder="Colour"
              />
              <label className="form__label--visually-hidden" htmlFor="mileage">
                Vehicle Mileage
              </label>
              <input
                className={`form__input__vehicle ${validMileageClass}`}
                id="mileage"
                name="mileage"
                type="number"
                autoComplete="off"
                value={mileage}
                onChange={onMileageChanged}
                onFocus={(e) => {
                  if (e.target.value === "Mileage") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Mileage";
                  }
                }}
                placeholder="Mileage"
              />
              {/* <label className="form__label--visually-hidden" htmlFor="images">
                Vehicle Images
              </label>
              <input
                className={`form__input__vehicle ${validImagesClass}`}
                id="images"
                name="images"
                type="text"
                value={images}
                onChange={onImagesChanged}
                onFocus={(e) => {
                  if (e.target.value === "Image") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Image";
                  }
                }}
                placeholder="Image"
              /> */}
              <label className="form__label--visually-hidden" htmlFor="images">
                Vehicle Images
              </label>
              <p className="newVehicleFormDrivetrain">Images Max 5</p>
              <input
                className={`form__input__images`}
                id="images"
                name="images"
                type="file"
                accept="image/*"
                multiple
                // value={images}
                onChange={onImagesChanged}
                // onFocus={(e) => {
                //   if (e.target.value === "Image") {
                //     e.target.value = "";
                //   }
                // }}
                // onBlur={(e) => {
                //   if (e.target.value === "") {
                //     e.target.value = "Image";
                //   }
                // }}
                // placeholder="Image"
              />
            </div>
            <div
              className="newVehicleFormVehicleDetailsColumn"
              style={{ marginTop: "20px" }}
            >
              {/* <label
                className="form__label--visually-hidden"
                htmlFor="latitude"
              >
                Latitude
              </label>
              <input
                className={`form__input__vehicle ${validLatitudeClass}`}
                id="latitude"
                name="latitude"
                type="number"
                value={location.latitude}
                onChange={onLocationChanged}
                onFocus={(e) => {
                  if (e.target.value === "Latiude") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Latitude";
                  }
                }}
                placeholder="Latitude"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="longitude"
              >
                Longitude
              </label>
              <input
                className={`form__input__vehicle ${validLongitudeClass}`}
                id="longitude"
                name="longitude"
                type="number"
                value={location.longitude}
                onChange={onLocationChanged}
                onFocus={(e) => {
                  if (e.target.value === "Latiude") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Longitude";
                  }
                }}
                placeholder="Longitude"
              /> */}
              <label className="form__label--visually-hidden" htmlFor="address">
                Address:{" "}
                <span className="nowrap">[houseNumberAndStreetName]</span>
              </label>
              <input
                className={`form__input__vehicle ${validhouseNumberAndStreetNameClass}`}
                id="houseNumberAndStreetName"
                name="houseNumberAndStreetName"
                type="text"
                autoComplete="off"
                value={address.houseNumberAndStreetName}
                onChange={onAddressChanged}
                onFocus={(e) => {
                  if (e.target.value === "House No and Street Name") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "House No and Street Name";
                  }
                }}
                placeholder="House No and Street Name"
              />

              <label className="form__label--visually-hidden" htmlFor="address">
                Address: <span className="nowrap">[locality]</span>
              </label>
              <input
                className={`form__input__vehicle ${validlocalityClass}`}
                id="locality"
                name="locality"
                type="text"
                autoComplete="off"
                value={address.locality}
                onChange={onAddressChanged}
                onFocus={(e) => {
                  if (e.target.value === "Line 2") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Line 2";
                  }
                }}
                placeholder="Line 2"
              />

              <label className="form__label--visually-hidden" htmlFor="address">
                Address: <span className="nowrap">[cityOrTown]</span>
              </label>
              <input
                className={`form__input__vehicle ${validcityOrTownClass}`}
                id="cityOrTown"
                name="cityOrTown"
                type="text"
                autoComplete="off"
                value={address.cityOrTown}
                onChange={onAddressChanged}
                onFocus={(e) => {
                  if (e.target.value === "City or Town") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "City or Town";
                  }
                }}
                placeholder="City or Town"
              />

              <label className="form__label--visually-hidden" htmlFor="address">
                Address: <span className="nowrap">[postcode]</span>
              </label>
              <input
                className={`form__input__vehicle ${validpostcodeClass}`}
                id="postcode"
                name="postcode"
                type="text"
                autoComplete="off"
                value={address.postcode}
                onChange={onAddressChanged}
                onFocus={(e) => {
                  if (e.target.value === "Postcode") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Postcode";
                  }
                }}
                placeholder="Postcode"
              />
            </div>
          </div>

          {imagesUrl.length > 0 && (
            <div className="newVehicleFormImagesCollection">
              <div className="imagesCollectionNewVehicle">
                <FaArrowCircleLeft
                  onClick={prevSlide}
                  className="arrow arrow-left"
                />
                {imagesUrl.map((item, idx) => {
                  // console.log(item);
                  return (
                    <img
                      src={item}
                      alt={item.alt}
                      key={idx}
                      className={slide === idx ? "slide" : "slide slide-hidden"}
                    />
                  );
                })}
                <FaArrowCircleRight
                  onClick={nextSlide}
                  className="arrow arrow-right"
                />
                <span className="indicators">
                  {imagesUrl.map((_, idx) => {
                    return (
                      <button
                        key={idx}
                        className={
                          slide === idx
                            ? "indicator"
                            : "indicator indicator-inactive"
                        }
                        onClick={() => setSlide(idx)}
                      ></button>
                    );
                  })}
                </span>
              </div>
            </div>
          )}

          <div className="newVehicleFormFinancialDetailis">
            <div
              className="newVehicleFormFinancialDetailisColumn"
              style={{ marginTop: "20px" }}
            >
              <p>Extra Details</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="pricePerDay"
              >
                Price Per Day
              </label>
              <input
                className={`form__input__vehicle ${validPricePerDayClass}`}
                id="pricePerDay"
                name="pricePerDay"
                type="number"
                autoComplete="off"
                value={pricePerDay}
                onChange={onPricePerDayChanged}
                onFocus={(e) => {
                  if (e.target.value === "PricePerDay") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "PricePerDay";
                  }
                }}
                placeholder="PricePerDay"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="weekend Price"
              >
                Weekend Price
              </label>
              <input
                className={`form__input__vehicle ${validPricePerDayClass}`}
                id="weekendPricePerDay"
                name="weekendPricePerDay"
                type="number"
                autoComplete="off"
                value={weekendPricePerDay}
                onChange={onWeekendPricePerDayChanged}
                onFocus={(e) => {
                  if (e.target.value === "Weekend Price") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Weekend Price";
                  }
                }}
                placeholder="Weekend Price/Day"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="weekly Price"
              >
                Weekly Price
              </label>
              <input
                className={`form__input__vehicle ${validPricePerDayClass}`}
                id="weeklyPrice"
                name="weeklyPrice"
                type="number"
                autoComplete="off"
                value={weeklyPrice}
                onChange={onWeeklyPriceChanged}
                onFocus={(e) => {
                  if (e.target.value === "Weekly Price") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Weekly Price";
                  }
                }}
                placeholder="Weekly Price"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="Monthly Price"
              >
                Monthly Price
              </label>
              <input
                className={`form__input__vehicle ${validPricePerDayClass}`}
                id="MonthlyPrice"
                name="MonthlyPrice"
                type="number"
                autoComplete="off"
                value={monthlyPrice}
                onChange={onMonthlyPriceChanged}
                onFocus={(e) => {
                  if (e.target.value === "Monthly Price") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Monthly Price";
                  }
                }}
                placeholder="Monthly Price"
              />
              <label className="form__label--visually-hidden" htmlFor="deposit">
                Vehicle Deposit
              </label>
              <input
                className={`form__input__vehicle ${validDepositClass}`}
                id="deposit"
                name="deposit"
                type="number"
                autoComplete="off"
                value={deposit}
                onChange={onDepositChanged}
                onFocus={(e) => {
                  if (e.target.value === "Deposit") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Deposit";
                  }
                }}
                placeholder="Deposit"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="chauffeurAvailable"
              >
                Vehicle Chauffeur Availability
              </label>
              <p className="newVehicleFormCheckboxPTag">
                Vehicle Available for chauffeur?
              </p>
              <input
                className={`form__input__vehicle ${validChauffeurAvailableClass}`}
                id="chauffeurAvailable"
                name="chauffeurAvailable"
                type="checkbox"
                checked={chauffeurAvailable}
                onChange={onChauffeurAvailableChanged}
              />
              <p className="newVehicleFormCheckboxPTag">
                Vehicle Available for chauffeur Only?
              </p>
              <input
                className={`form__input__vehicle ${validChauffeurAvailableClass}`}
                id="chauffeurOnly"
                name="chauffeurOnly"
                type="checkbox"
                checked={chauffeurOnly}
                onChange={onChauffeurOnlyChanged}
              />
              <label
                className="form__label--visually-hidden"
                htmlFor=" chauffeurPricePerDay"
              >
                chauffeurPricePerDay
              </label>
              <p className="newVehicleFormCheckboxPTag">
                This is on top of the Price Per Day
              </p>
              <input
                className={`form__input__vehicle ${validChauffeurPricePerDayClass}`}
                id=" chauffeurPricePerDay"
                name=" additionalDriverPricePerDay"
                type="number"
                autoComplete="off"
                value={chauffeurPricePerDay}
                onChange={onChauffeurPricePerDayChanged}
                onFocus={(e) => {
                  if (e.target.value === " chauffeurPricePerDay") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = " chauffeurPricePerDay";
                  }
                }}
                placeholder="Chauffeur Price/Day"
              />
            </div>
            <div
              className="newVehicleFormFinancialDetailisColumn"
              style={{ marginTop: "40px" }}
            >
              <label
                className="form__label--visually-hidden"
                htmlFor="mileageLimit"
              >
                Vehicle Mileage Limit
              </label>
              <input
                className={`form__input__vehicle ${validMileageLimitClass}`}
                id="mileageLimit"
                name="mileageLimit"
                type="number"
                autoComplete="off"
                value={mileageLimit}
                onChange={onMileageLimitChanged}
                onFocus={(e) => {
                  if (e.target.value === "MileageLimit") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "MileageLimit";
                  }
                }}
                placeholder="MileageLimit"
              />
              <p className="newVehicleFormCheckboxPTag">
                Allow an Additional Driver?
              </p>
              <input
                className={`form__input__vehicle ${validChauffeurAvailableClass}`}
                id="allowAdditionalDriver"
                name="allowAdditionalDriver"
                type="checkbox"
                checked={allowAdditionalDriver}
                onChange={onAllowAdditionalDriverChanged}
              />
              <label
                className="form__label--visually-hidden"
                htmlFor=" additionalDriverPricePerDay"
              >
                additionalDriverPricePerDay
              </label>
              <input
                className={`form__input__vehicle ${validAdditionalDriverPricePerDayClass}`}
                id=" additionalDriverPricePerDay"
                name=" additionalDriverPricePerDay"
                type="number"
                autoComplete="off"
                value={additionalDriverPricePerDay}
                onChange={onAdditionalDriverPricePerDay}
                onFocus={(e) => {
                  if (e.target.value === " additionalDriverPricePerDay") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = " additionalDriverPricePerDay";
                  }
                }}
                placeholder="Extra Driver/day Fee"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor=" minimum age"
              >
                minimum age
              </label>
              <input
                className={`form__input__vehicle ${validMinimumAgeClass}`}
                id=" minimumAge"
                name=" minimumAge"
                type="number"
                autoComplete="off"
                value={minimumAge}
                onChange={onMinimumAgeChanged}
                onFocus={(e) => {
                  if (e.target.value === " Minimum Age") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = " Minimum Age";
                  }
                }}
                placeholder="Minimum Age"
              />
            </div>
            <div
              className="newVehicleFormFinancialDetailisColumn"
              style={{ marginTop: "20px" }}
            >
              <label
                className="form__label--visually-hidden form__checkbox-container "
                htmlFor="owner"
              >
                ASSIGNED TO:
              </label>
              <select
                id="owner"
                name="owner"
                className={`form__input__vehicle ${validOwnerClass}`}
                value={owner}
                onChange={onOwnerChanged}
                disabled
              >
                {options}
              </select>
              <label className="form__label--visually-hidden" htmlFor="status">
                Vehicle Status
              </label>
              <p className="newVehicleFormCheckboxPTag">Vehicle Status</p>
              <select
                className={`form__input__vehicle ${validStatusClass}`}
                name="status"
                value={status}
                onChange={onStatusChanged}
              >
                {vehicleStatusOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <label
                className="form__label--visually-hidden"
                htmlFor="available"
              >
                Vehicle Availability
              </label>
              <p className="newVehicleFormCheckboxPTag">
                Vehicle Available for hire?
              </p>
              <input
                className={`form__input__vehicle ${validAvailableClass}`}
                id="available"
                name="available"
                type="checkbox"
                checked={available}
                onChange={onAvailableChanged}
              />
              <p className="newVehicleFormCheckboxPTag">
                Turn Full Payment Required On For This Car?
              </p>
              <input
                className={`form__input__vehicle ${validAvailableClass}`}
                id="fullPaymentRequired"
                name="fullPaymentRequired"
                type="checkbox"
                checked={fullPaymentRequired}
                onChange={onPaymentChange}
                disabled={!stripeOnboardingComplete}
              />
              {/* <p className="newVehicleFormCheckboxPTag">
                Turn Deposit Required On For This Car?
              </p>
              <input
                className={`form__input__vehicle ${validAvailableClass}`}
                id="depositRequired"
                name="depositRequired"
                type="checkbox"
                checked={depositRequired}
                onChange={onDepositRequiredChange}
                disabled={!stripeOnboardingComplete}
              /> */}
              {isEnterprisePlan ? (
                <>
                  <p className="newVehicleFormCheckboxPTag">
                    Turn Deposit Required On For This Car?
                  </p>
                  <input
                    className={`form__input__vehicle ${validAvailableClass}`}
                    id="depositRequired"
                    name="depositRequired"
                    type="checkbox"
                    checked={depositRequired}
                    onChange={onDepositRequiredChange}
                    disabled={!stripeOnboardingComplete}
                  />
                </>
              ) : null}
            </div>
          </div>
          <div className="newVehicleFormDeliveryDetails">
            <div className="newVehicleFormDeliveryDetailsColumn">
              {" "}
              <p>Delivery Details</p>
              <label
                className="form__label--visually-hidden"
                htmlFor="delivery"
              >
                Accept Home Delivery
              </label>
              <p className="newVehicleFormCheckboxPTag">
                Vehicle Available for home Delivery?
              </p>
              <input
                className={`form__input__vehicle ${validDeliveryClass}`}
                id="acceptHomeDelivery"
                name="acceptHomeDelivery"
                type="checkbox"
                checked={delivery.acceptHomeDelivery}
                onChange={onDeliveryChanged}
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="delivery"
              >
                Accept Dealership Pick Up
              </label>
              <p className="newVehicleFormCheckboxPTag">
                Vehicle Available for to pick up at Dealership?
              </p>
              <input
                className={`form__input__vehicle ${validDeliveryClass}`}
                id="acceptPickUpFromDealership"
                name="acceptPickUpFromDealership"
                type="checkbox"
                checked={delivery.acceptPickUpFromDealership}
                onChange={onDeliveryChanged}
              />
            </div>
            <div className="newVehicleFormDeliveryDetailsColumn">
              <label
                className="form__label--visually-hidden"
                htmlFor="Home Delivery Max Mileage"
              >
                Price Per Day
              </label>
              <p className="maxDeliveryMileage">
                Home Delivery Maximum Distance (miles){" "}
              </p>
              <input
                className={`form__input__vehicle ${validDeliveryClass}`}
                id="homeDeliveryMaximumMileage"
                name="homeDeliveryMaximumMileage"
                type="number"
                value={delivery.homeDeliveryMaximumMileage}
                onChange={onDeliveryChanged}
                onFocus={(e) => {
                  if (e.target.value === "Max Distance") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Max Distance";
                  }
                }}
                placeholder="Max Distance"
              />
              <label
                className="form__label--visually-hidden"
                htmlFor="Home Delivery Max Mileage"
              >
                Price Per Day
              </label>
              <p className="deliveryCostPerMile">
                Home Delivery Charge Per Mile
              </p>
              <input
                className={`form__input__vehicle ${validDeliveryClass}`}
                id="homeDeliveryChargePricePerMile"
                name="homeDeliveryChargePricePerMile"
                type="number"
                value={delivery.homeDeliveryChargePricePerMile}
                onChange={onDeliveryChanged}
                onFocus={(e) => {
                  if (e.target.value === "Price Per Mile") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Price Per Mile";
                  }
                }}
                placeholder="Price Per Mile"
              />
            </div>
          </div>
          <div className="newVehicleFormAvailabilityDetailis">
            <div className="newVehicleFormAvailabilityDetailisColumn">
              <p
                data-tooltip-id="infoTooltip"
                data-tooltip-content="What times can the vehicle be collected?"
              >
                Vehicle Start Times
              </p>
              {/* Start Times */}
              {timeOptions.map((timeOption) => (
                <div key={timeOption}>
                  <input
                    type="checkbox"
                    name="startTimes"
                    value={timeOption}
                    checked={availability.startTimes.includes(timeOption)}
                    onChange={onAvailabilityChanged}
                  />
                  <label className="vehicleStartTimesLabel">{timeOption}</label>
                </div>
              ))}
            </div>
            <div className="newVehicleFormAvailabilityDetailisColumn">
              <p
                data-tooltip-id="infoTooltip"
                data-tooltip-content="What times can the vehicle be Returned?"
              >
                Vehicle Drop Off Times
              </p>
              {/* End Times */}
              {timeOptions.map((timeOption) => (
                <div key={timeOption}>
                  <input
                    type="checkbox"
                    name="endTimes"
                    value={timeOption}
                    checked={availability.endTimes.includes(timeOption)}
                    onChange={onAvailabilityChanged}
                  />
                  <label className="vehicleStartTimesLabel">{timeOption}</label>
                </div>
              ))}
            </div>
            <div className="newVehicleFormAvailabilityDetailisColumn">
              <p
                data-tooltip-id="infoTooltip"
                data-tooltip-content="What Days Can The Vehicle Be Either Collected or Returned/Dealership Business Days"
              >
                Vehicle Availability Days
              </p>
              <Tooltip
                id="infoTooltip"
                place="top"
                type="dark"
                effect="float"
                className="custom_tooltip--VehicleForms"
              />
              {/* Days of the Week */}
              {daysOfWeekOptions.map((dayOption) => (
                <div key={dayOption}>
                  <input
                    type="checkbox"
                    name="daysOfWeekDealershipOpenForThisVehicle"
                    value={dayOption}
                    checked={availability.daysOfWeekDealershipOpenForThisVehicle.includes(
                      dayOption
                    )}
                    onChange={onAvailabilityChanged}
                  />
                  <label className="vehicleStartTimesLabel">{dayOption}</label>
                </div>
              ))}
              <div className="newVehicleFormSameDayReturnAndStart">
                <h4>Same Day Return and Pick Up</h4>
                <input
                  type="checkbox"
                  name="acceptSameDayReturnAndStart"
                  checked={sameDayReturnAndStart.acceptSameDayReturnAndStart}
                  onChange={onSameDayReturnAndStartChange}
                  className="newVehicleFormSameDayReturnAndStartCheck"
                />
                <label
                  className="vehicleStartTimesLabel"
                  data-tooltip-id="infoTooltip"
                  data-tooltip-content="If a Booking is Returned During the Day, Can a New Booking Start the Same Day"
                >
                  Accept Same Day Return and Start
                </label>
                <p>
                  Minimum Time Gap Between Return of Vehicle and New Booking
                  [Hours]
                </p>
                <input
                  type="number"
                  name="minGapBetweenBookings"
                  value={sameDayReturnAndStart.minGapBetweenBookings}
                  onChange={onSameDayReturnAndStartChange}
                  className={`form__input__vehicle ${validAvailableClass}`}
                />
              </div>
            </div>
          </div>
          <div className="newVehicleFormNotes">
            <p>Notes</p>
            <div className="newVehicleFormNotesInput">
              <label className="form__label--visually-hidden" htmlFor="notes">
                Vehicle Notes
              </label>
              {/* <input
                className={`form__input__vehicle__notes ${validNotesClass}`}
                id="notes"
                name="notes"
                type="text"
                autoComplete="off"
                value={notes}
                onChange={onNotesChanged}
                onFocus={(e) => {
                  if (e.target.value === "Notes") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Notes";
                  }
                }}
              /> */}
              <textarea
                className={`form__input__vehicle__notes ${validNotesClass}`}
                id="notes"
                name="notes"
                autoComplete="off"
                value={notes}
                onChange={onNotesChanged}
                placeholder="Notes"
                onFocus={(e) => {
                  if (e.target.value === "Notes") {
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    e.target.value = "Notes";
                  }
                }}
                rows="4" // <!-- This defines the height of the textarea. Adjust the value as needed. -->
              ></textarea>
            </div>
          </div>
        </div>
      </form>
    </>
  );
  return content;
};

export default EditVehicleForm;
