import { useGetAvailableVehiclesQuery } from "./vehiclesApiSlice";
import Vehicle from "./Vehicle";
import { DateRangeContext } from "../../context/DateRangeContext";
import { useContext, useEffect, useState } from "react";

import { HashLoader } from "react-spinners";
import { Link, useParams } from "react-router-dom";

const DealerVehicleList = () => {
  const { ownerId } = useParams();
  const [filteredVehicle, setFilteredVehicle] = useState([]);

  const {
    startDate,
    endDate,
    startTime,
    endTime,
    makeOption,
    modelOption,
    modelVariantOption,
    postcode,
    distanceRangeOption,
    priceRangeOption,
    setAvailableVehiclesCount,
  } = useContext(DateRangeContext);

  const {
    data: vehicles,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAvailableVehiclesQuery(
    {
      startDate,
      endDate,
      startTime,
      endTime,
      makeOption,
      modelOption,
      modelVariantOption,
      postcode,
      distanceRangeOption,
      priceRangeOption,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    // Check if vehicles data is available and not in a loading/error state
    if (isSuccess && vehicles) {
      // Filter vehicles based on companyName
      const userVehicles = Object.values(vehicles.entities).filter(
        (vehicle) => vehicle.owner === ownerId
      );

      // Set filtered vehicles to state
      setFilteredVehicle(userVehicles);
    }
  }, [ownerId, isSuccess, vehicles]);

  useEffect(() => {
    if (isSuccess && filteredVehicle) {
      setAvailableVehiclesCount(filteredVehicle.length); // Update available vehicles count in context
    }
  }, [isSuccess, filteredVehicle, setAvailableVehiclesCount]);

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;

  if (isError) {
    content = <p className="vehicleListErrMsg">{error?.data?.message}</p>;
  }

  //   console.log(filteredVehicle);

  if (isSuccess) {
    const ids = filteredVehicle.map((vehicle) => vehicle.id);

    const vehicleList = ids?.length
      ? ids.map((vehicleId) => (
          <div key={vehicleId}>
            <Link to={`/vehicle/${vehicleId}`}>
              <Vehicle key={vehicleId} vehicleId={vehicleId} />
            </Link>
          </div>
        ))
      : null;

    content = <div className="vehicleListGrid">{vehicleList}</div>;
  }

  return content;
};

export default DealerVehicleList;
