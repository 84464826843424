import { useGetAllBookingsWithRejectedQuery } from "../../bookings/bookingsApiSlice";
import { useGetVehiclesQuery } from "../../vehicles/vehiclesApiSlice";
import useAuth from "../../../hooks/useAuth";
import { HashLoader } from "react-spinners";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaRandom, FaSearch } from "react-icons/fa";

const DashboardReports = () => {
  const { email, companyName } = useAuth();
  const navigate = useNavigate();

  const [filteredBookings, setFilteredBookings] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);

  const {
    data: bookings,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllBookingsWithRejectedQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: vehicles,
    isLoading: isLoadingVehicles,
    isSuccess: isSuccessVehicles,
    isError: isErrorVehicles,
    error: errorVehicles,
  } = useGetVehiclesQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("newest");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    // Check if vehicles data is available and not in a loading/error state
    if (isSuccessVehicles && vehicles) {
      // Filter vehicles based on companyName
      const userVehicles = Object.values(vehicles.entities).filter(
        (vehicle) => vehicle.companyName === companyName
      );

      // Set filtered vehicles to state
      setFilteredVehicles(userVehicles);
    }
  }, [companyName, isSuccessVehicles, vehicles]);

  useEffect(() => {
    if (isSuccess && email) {
      const filtered = Object.values(bookings.entities).filter(
        (booking) => booking.vehicleOwner.email === email
      );
      setFilteredBookings(filtered);
    }
  }, [bookings, isSuccess, email]);

  const getBookingsCountForToday = () => {
    if (filteredBookings.length === 0) {
      return 0;
    }

    const today = new Date();
    const todayDateString = today.toISOString().split("T")[0];

    const bookingsForToday = filteredBookings.filter(
      (booking) => booking.startDate.split("T")[0] === todayDateString
    );

    return bookingsForToday.length;
  };

  const getBookingsCountForReturn = () => {
    if (filteredBookings.length === 0) {
      return 0;
    }

    const today = new Date();
    const todayDateString = today.toISOString().split("T")[0];

    const bookingsForReturn = filteredBookings.filter(
      (booking) => booking.endDate.split("T")[0] === todayDateString
    );

    return bookingsForReturn.length;
  };

  const getBookingsWithTBC = () => {
    if (filteredBookings.length === 0) {
      return 0;
    }

    const bookingsWithTBC = filteredBookings.filter(
      (booking) =>
        booking.bookingProgress === "To Be Confirmed" ||
        booking.bookingProgress === "To Be Confirmed Deposit Received" ||
        booking.bookingProgress === "To be Confirmed Full Payment Received"
    );

    // console.log(bookingsWithTBC);

    return bookingsWithTBC.length;
  };

  const getBookingsTodayNew = () => {
    if (filteredBookings.length === 0) {
      return 0;
    }

    const today = new Date().toISOString().split("T")[0];

    const bookingsToday = filteredBookings.filter(
      (booking) => booking.dateOfBooking === today
    );

    return bookingsToday.length;
  };

  const getBookingsWithAcceptedPaymentNotReceived = () => {
    if (filteredBookings.length === 0) {
      return 0;
    }

    const bookingsWithAcceptedPaymentNotReceived = filteredBookings.filter(
      (booking) =>
        booking.bookingProgress === "Accepted Payment Not Received" ||
        booking.bookingProgress === "Accepted Full Payment Required" ||
        booking.bookingProgress ===
          "Accepted Hire Price Excluding Deposit Required" ||
        booking.bookingProgress === "Accepted Deposit Required"
    );

    return bookingsWithAcceptedPaymentNotReceived.length;
  };

  const getBookingsWithAcceptedPaymentReceived = () => {
    if (filteredBookings.length === 0) {
      return 0;
    }

    const bookingsWithAcceptedPaymentReceived = filteredBookings.filter(
      (booking) =>
        booking.bookingProgress === "Accepted Deposit Only Received" ||
        booking.bookingProgress === "Accepted Full Payment Received"
    );

    return bookingsWithAcceptedPaymentReceived.length;
  };

  const getBookingsRejected = () => {
    if (filteredBookings.length === 0) {
      return 0;
    }

    const bookingsRejected = filteredBookings.filter(
      (booking) => booking.bookingProgress === "Rejected"
    );

    return bookingsRejected.length;
  };

  const getVehiclesWithDamageCount = (filteredVehicles) => {
    if (!filteredVehicles || filteredVehicles.length === 0) {
      return 0;
    }

    const vehiclesWithDamage = filteredVehicles.filter(
      (vehicle) =>
        vehicle.status === "Major Damage" || vehicle.status === "Minor Damage"
    );

    return vehiclesWithDamage.length;
  };

  const handleH3Click = () => {
    // Navigate to the desired route
    navigate("/dashboard/timeline");
  };

  const handleDeliveryClick = () => {
    navigate("/dashboard/reports/dueForDelivery");
  };

  const handleReturnClick = () => {
    navigate("/dashboard/reports/dueForReturn");
  };

  const handleDamageClick = () => {
    navigate("/dashboard/reports/damagedVehicles");
  };

  const handleTBCClick = () => {
    navigate("/dashboard/reports/toBeConfirmed");
  };

  const handleAPNRClick = () => {
    navigate("/dashboard/reports/acceptedPaymentNotReceived");
  };

  const handleAPRClick = () => {
    navigate("/dashboard/reports/acceptedPaymentReceived");
  };

  const handleRejectedClick = () => {
    navigate("/dashboard/reports/rejected");
  };

  const totalPages = Math.ceil(filteredBookings.length / itemsPerPage);

  // Set the number of pages you want to show at once
  const maxVisiblePages = 8;

  // Calculate the start and end of the range of buttons to show
  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

  // Adjust startPage in case we're near the end
  const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

  let content;

  if (isLoading && isLoadingVehicles) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;
  if (isErrorVehicles)
    content = <p className="errmsg">{errorVehicles?.data?.message}</p>;

  if (isSuccess) {
    const damageCount = getVehiclesWithDamageCount(filteredVehicles);

    content = (
      <div className="dashboardReports">
        <h1>Reports</h1>
        <div className="dashboardReportsPages">
          <div className="dashboardReportsDiv">
            <div className="dashboardReportsDivIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/Screenshot+2024-04-08+at+19.07.53.png"
                alt="Due for Delivery"
              />
            </div>
            <div className="dashboardReportsDivDetails">
              <p>Due for Delivery</p>
              <h3
                onClick={handleDeliveryClick}
                className="dashboardReportDueForDelivery"
              >
                {getBookingsCountForToday()}
              </h3>
            </div>
          </div>
          <div className="dashboardReportsDiv">
            <div className="dashboardReportsDivIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/Screenshot+2024-04-08+at+19.06.40.png"
                alt="Due for Return"
              />
            </div>
            <div className="dashboardReportsDivDetails">
              <p>Due for Return</p>
              <h3
                onClick={handleReturnClick}
                className="dashboardReportDueForDelivery"
              >
                {getBookingsCountForReturn()}
              </h3>
            </div>
          </div>
          <div className="dashboardReportsDiv">
            <div className="dashboardReportsDivIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/IMG_2704.JPG"
                alt="Damaged Vehicles"
              />
            </div>
            <div className="dashboardReportsDivDetails">
              <p>Damaged Vehicles</p>
              <h3
                onClick={handleDamageClick}
                className="dashboardReportDueForDelivery"
              >
                {damageCount}
              </h3>
            </div>
          </div>
          <div className="dashboardReportsDiv">
            <div className="dashboardReportsDivIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/Screenshot+2024-04-08+at+19.07.14.png"
                alt="Vehicle Time lines"
              />
            </div>
            <div className="dashboardReportsDivDetails">
              <p> Vehicle Timelines</p>
              <h3
                onClick={handleH3Click}
                className="dashboardReportDivDetailsTimeline"
              >
                Click Here
              </h3>
            </div>
          </div>
        </div>
        <h1>Booking Requests - {getBookingsTodayNew()} New Requests Today!</h1>
        <div className="dashboardReportsPages">
          <div className="dashboardReportsDiv">
            <div className="dashboardReportsDivIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/Screenshot+2024-04-08+at+19.07.53.png"
                alt="Due for Delivery"
              />
            </div>
            <div className="dashboardReportsDivDetails">
              <p>To be Confirmed</p>
              <h3
                onClick={handleTBCClick}
                className="dashboardReportDueForDelivery"
              >
                {getBookingsWithTBC()}
              </h3>
            </div>
          </div>
          <div className="dashboardReportsDiv">
            <div className="dashboardReportsDivIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/Screenshot+2024-04-08+at+19.06.40.png"
                alt="Due for Return"
              />
            </div>
            <div className="dashboardReportsDivDetails">
              <p>Accepted (payment not recevied)</p>
              <h3
                onClick={handleAPNRClick}
                className="dashboardReportDueForDelivery"
              >
                {getBookingsWithAcceptedPaymentNotReceived()}
              </h3>
            </div>
          </div>
          <div className="dashboardReportsDiv">
            <div className="dashboardReportsDivIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/IMG_2704.JPG"
                alt="Damaged Vehicles"
              />
            </div>
            <div className="dashboardReportsDivDetails">
              <p>Accepted (payment received)</p>
              <h3
                onClick={handleAPRClick}
                className="dashboardReportDueForDelivery"
              >
                {getBookingsWithAcceptedPaymentReceived()}
              </h3>
            </div>
          </div>
          <div className="dashboardReportsDiv">
            <div className="dashboardReportsDivIcon">
              <img
                src="https://imascars-website-images.s3.eu-north-1.amazonaws.com/Screenshot+2024-04-08+at+19.07.14.png"
                alt="Vehicle Time lines"
              />
            </div>
            <div className="dashboardReportsDivDetails">
              <p>Rejected</p>
              <h3
                onClick={handleRejectedClick}
                className="dashboardReportDivDetailsTimeline"
              >
                {getBookingsRejected()}
              </h3>
            </div>
          </div>
        </div>
        <div className="dashboardDealershipRecentOrdersReportsPage">
          <div className="myOrdersDealershipFilterAndSearch">
            <div className="filterBtn">
              <button
                onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}
              >
                <FaRandom className="filterIconMyOrdersPageCustomer" />
                Filter
              </button>
              {filterDropdownOpen && (
                <ul
                  className={`filterDropdown ${filterDropdownOpen && "show"}`}
                >
                  <li onClick={() => setSelectedFilter("newest")}>Newest</li>
                  <li onClick={() => setSelectedFilter("oldest")}>Oldest</li>
                  <li onClick={() => setSelectedFilter("mostExpensive")}>
                    Price High-Low
                  </li>
                  <li onClick={() => setSelectedFilter("leastExpensive")}>
                    Price Low-High
                  </li>
                </ul>
              )}
            </div>
            <div className="searchBarOrdersPage">
              <FaSearch className="searchIconMyOrdersPageCustomer" />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
          </div>

          <table className="dashboardDealershipRecentOrdersTable">
            <thead>
              <tr>
                <th>Order</th>
                <th>Date</th>
                <th>Item</th>
                <th>Duration</th>
                <th>Customer</th>
                <th>Booking Progress</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {filteredBookings
                .filter((booking) => {
                  const searchTerms = searchQuery.toLowerCase().split(" ");
                  // return (
                  //   searchTerms.length === 0 ||
                  //   searchTerms.some(
                  //     (term) =>
                  //       booking.id.toLowerCase().includes(term) ||
                  //       booking.dateOfBooking.toLowerCase().includes(term) ||
                  //       booking.vehicleDetails.make
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.vehicleDetails.model
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.vehicleDetails.modelVariant
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.customer.firstname
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.customer.lastname
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.bookingStatus.toLowerCase().includes(term) ||
                  //       booking.totalPrice.toString().includes(term)
                  //   )
                  // );
                  return (
                    searchTerms.length === 0 ||
                    searchTerms.some(
                      (term) =>
                        booking.id?.toLowerCase().includes(term) ||
                        booking.dateOfBooking?.toLowerCase().includes(term) ||
                        booking.vehicleDetails?.make
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.vehicleDetails?.model
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.vehicleDetails?.modelVariant
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.customer?.firstname
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.customer?.lastname
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.bookingStatus?.toLowerCase().includes(term) ||
                        booking.totalPrice?.toString().includes(term) ||
                        booking.bookingProgress?.includes(term)
                    )
                  );
                })
                .sort((a, b) => {
                  switch (selectedFilter) {
                    case "newest":
                      return (
                        new Date(b.dateOfBooking) - new Date(a.dateOfBooking)
                      );
                    case "oldest":
                      return (
                        new Date(a.dateOfBooking) - new Date(b.dateOfBooking)
                      );
                    case "mostExpensive":
                      return b.totalPrice - a.totalPrice;
                    case "leastExpensive":
                      return a.totalPrice - b.totalPrice;
                    default:
                      return 0;
                  }
                })
                .slice(indexOfFirstItem, indexOfLastItem)
                .map((booking) => (
                  <tr key={booking.id}>
                    <td
                      onClick={() => navigate(`/bookings/${booking.id}`)}
                      className="dashboardOrdersBookingId"
                    >
                      {booking.id}
                    </td>
                    <td>{booking.dateOfBooking}</td>
                    <td>
                      {booking.vehicleDetails.make}{" "}
                      {booking.vehicleDetails.model}{" "}
                      {booking.vehicleDetails.modelVariant}
                    </td>
                    <td>
                      {new Date(booking.startDate).toLocaleDateString("en-GB", {
                        month: "short",
                        day: "numeric",
                      })}
                      -
                      {new Date(booking.endDate).toLocaleDateString("en-GB", {
                        month: "short",
                        day: "numeric",
                      })}{" "}
                      (
                      {Math.max(
                        0,
                        Math.ceil(
                          (new Date(booking.endDate) -
                            new Date(booking.startDate)) /
                            (1000 * 3600 * 24)
                        )
                      )}
                      days)
                    </td>
                    <td>
                      {booking.customer
                        ? `${booking.customer.firstname} ${booking.customer.lastname}`
                        : booking.mainCustomer.fullname}
                    </td>
                    <td>{booking.bookingProgress || ""}</td>
                    <td>£{booking.totalPrice}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="pagination">
            {/* Previous Button */}
            {currentPage > 1 && (
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                className="paginationArrows"
              >
                Prev
              </button>
            )}

            {/* {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                className={index + 1 === currentPage ? "active" : ""}
              >
                {index + 1}
              </button>
            ))} */}

            {Array.from(
              { length: endPage - adjustedStartPage + 1 },
              (_, index) => adjustedStartPage + index
            ).map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => setCurrentPage(pageNumber)}
                className={pageNumber === currentPage ? "active" : ""}
              >
                {pageNumber}
              </button>
            ))}

            {/* Next Button */}
            {currentPage < totalPages && (
              <button onClick={() => setCurrentPage(currentPage + 1)}>
                Next
              </button>
            )}
          </div>
        </div>
        {/* <div className="progressSystemExplained">
          <h3>How Does The Booking Request Sysytem Work?</h3>
          <p>
            Every booking has a booking progress which can either be "Rejected",
            "To Be Confirmed", "Accepted Payment Not Received", "Accepted
            Payment Received".
          </p>
          <br />
          <p>
            All new booking requests that come through the website default to
            "To Be Confirmed". This temporarily holds this bookings spot.
          </p>
          <br />
          <p>
            When you change the booking progress to either of the accepted
            options they will now show up in My Orders.
          </p>
          <br />
          <p>
            Any Bookings which have a progress of "Rejected" will essentially be
            deleted, and the booking slot will open up again.
          </p>
          <br />
          <p>
            Once IMAS Cars can facilitate payments the bookings will
            automatically go to "Accepted Payment Received".
          </p>
        </div> */}
      </div>
    );
  }

  return content;
};

export default DashboardReports;
