import { useGetBookingsQuery } from "../../bookings/bookingsApiSlice";
import useAuth from "../../../hooks/useAuth";
import { HashLoader } from "react-spinners";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaRandom, FaSearch } from "react-icons/fa";

const ToBeConfirmed = () => {
  const { email } = useAuth();
  const navigate = useNavigate();
  const [filteredBookings, setFilteredBookings] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("newest");

  const {
    data: bookings,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetBookingsQuery(undefined, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isSuccess && email) {
      const filtered = Object.values(bookings.entities).filter(
        (booking) => booking.vehicleOwner.email === email
      );
      setFilteredBookings(filtered);
    }
  }, [bookings, isSuccess, email]);

  const getBookingsWithTBC = () => {
    if (filteredBookings.length === 0) {
      return [];
    }

    const tbcBookings = filteredBookings.filter(
      (booking) =>
        booking.bookingProgress === "To Be Confirmed" ||
        booking.bookingProgress === "To Be Confirmed Deposit Received" ||
        booking.bookingProgress === "To be Confirmed Full Payment Received"
    );

    return tbcBookings;
  };

  let content;

  if (isLoading) content = <HashLoader color="#36d7b7" />;
  if (isError) content = <p className="errmsg">{error?.data?.message}</p>;

  let tbcBookings;

  if (isSuccess) {
    tbcBookings = getBookingsWithTBC();

    content = (
      <div className="tbcPage">
        <h3>To Be Confirmed</h3>
        <div className="toBeConfirmed">
          <div className="myOrdersDealershipFilterAndSearch">
            <div className="filterBtn">
              <button
                onClick={() => setFilterDropdownOpen(!filterDropdownOpen)}
              >
                <FaRandom className="filterIconMyOrdersPageCustomer" />
                Filter
              </button>
              {filterDropdownOpen && (
                <ul
                  className={`filterDropdown ${filterDropdownOpen && "show"}`}
                >
                  <li onClick={() => setSelectedFilter("newest")}>Newest</li>
                  <li onClick={() => setSelectedFilter("oldest")}>Oldest</li>
                  <li onClick={() => setSelectedFilter("mostExpensive")}>
                    Price High-Low
                  </li>
                  <li onClick={() => setSelectedFilter("leastExpensive")}>
                    Price Low-High
                  </li>
                </ul>
              )}
            </div>
            <div className="searchBarOrdersPage">
              <FaSearch className="searchIconMyOrdersPageCustomer" />
              <input
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
          </div>

          <table className="dashboardDealershipRecentOrdersTable">
            <thead>
              <tr>
                <th>Order</th>
                <th>Date</th>
                <th>Item</th>
                <th>Duration</th>
                <th>Customer</th>
                <th>Booking Progress</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {tbcBookings
                .filter((booking) => {
                  const searchTerms = searchQuery.toLowerCase().split(" ");
                  // return (
                  //   searchTerms.length === 0 ||
                  //   searchTerms.some(
                  //     (term) =>
                  //       booking.id.toLowerCase().includes(term) ||
                  //       booking.dateOfBooking.toLowerCase().includes(term) ||
                  //       booking.vehicleDetails.make
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.vehicleDetails.model
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.vehicleDetails.modelVariant
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.customer.firstname
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.customer.lastname
                  //         .toLowerCase()
                  //         .includes(term) ||
                  //       booking.bookingStatus.toLowerCase().includes(term) ||
                  //       booking.totalPrice.toString().includes(term)
                  //   )
                  // );
                  return (
                    searchTerms.length === 0 ||
                    searchTerms.some(
                      (term) =>
                        booking.id?.toLowerCase().includes(term) ||
                        booking.dateOfBooking?.toLowerCase().includes(term) ||
                        booking.vehicleDetails?.make
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.vehicleDetails?.model
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.vehicleDetails?.modelVariant
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.customer?.firstname
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.customer?.lastname
                          ?.toLowerCase()
                          .includes(term) ||
                        booking.bookingStatus?.toLowerCase().includes(term) ||
                        booking.totalPrice?.toString().includes(term) ||
                        booking.bookingProgress?.includes(term)
                    )
                  );
                })
                .sort((a, b) => {
                  switch (selectedFilter) {
                    case "newest":
                      return (
                        new Date(b.dateOfBooking) - new Date(a.dateOfBooking)
                      );
                    case "oldest":
                      return (
                        new Date(a.dateOfBooking) - new Date(b.dateOfBooking)
                      );
                    case "mostExpensive":
                      return b.totalPrice - a.totalPrice;
                    case "leastExpensive":
                      return a.totalPrice - b.totalPrice;
                    default:
                      return 0;
                  }
                })
                .map((booking) => (
                  <tr key={booking.id}>
                    <td
                      onClick={() => navigate(`/bookings/${booking.id}`)}
                      className="dashboardOrdersBookingId"
                    >
                      {booking.id}
                    </td>
                    <td>{booking.dateOfBooking}</td>
                    <td>
                      {booking.vehicleDetails.make}{" "}
                      {booking.vehicleDetails.model}{" "}
                      {booking.vehicleDetails.modelVariant}
                    </td>
                    <td>
                      {new Date(booking.startDate).toLocaleDateString("en-GB", {
                        month: "short",
                        day: "numeric",
                      })}
                      -
                      {new Date(booking.endDate).toLocaleDateString("en-GB", {
                        month: "short",
                        day: "numeric",
                      })}{" "}
                      (
                      {Math.max(
                        0,
                        Math.ceil(
                          (new Date(booking.endDate) -
                            new Date(booking.startDate)) /
                            (1000 * 3600 * 24)
                        )
                      )}
                      days)
                    </td>
                    <td>
                      {booking.customer
                        ? `${booking.customer.firstname} ${booking.customer.lastname}`
                        : booking.mainCustomer.fullname}
                    </td>
                    <td>{booking.bookingProgress || ""}</td>
                    <td>£{booking.totalPrice}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return content;
};

export default ToBeConfirmed;
